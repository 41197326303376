import React from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  Breadcrumbs,
  Card,
  Grid,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
  Tooltip,
} from "@mui/material";
import Button from "@mui/material/Button";
import { DefaultEditor } from "react-simple-wysiwyg";
import HttpService from "../../../services/HttpService";
import useFetchKindreds from "../../../hooks/useFetchKindreds";
import useFetchQuaters from "../../../hooks/useFetchQuaters";
import useFetchLocationTypes from "../../../hooks/useFetchLocationTypes";
import CustomModal from "../../templates/CustomModal";
import {
  Delete,
  Edit,
  MailOutlineOutlined,
  MessageOutlined,
  Send,
  Settings,
  ToggleOff,
  ToggleOn,
} from "@mui/icons-material";
import ConfirmModal from "../../templates/ConfirmModal";
import PlaceHolder from "../../templates/PlaceHolder";
import Footer from "../../Footer/Footer";
import Header from "../../Header/Header";
import * as processHtml from "../../../services/processHtml";
import LikeTemplate from "../../templates/LikeTemplate";

const MembersDetail = () => {
  const params = useParams();
  console.log("params:", params);
  const isParam = params.mId ? true : false;
  const { decodeHtml } = processHtml;
  let [loading, setLoading] = React.useState<any>(false);
  let [loaded, setLoaded] = React.useState<any>(false);
  let [member, setMember] = React.useState<any>({ designation: "0" });

  React.useEffect(() => {
    getMember(params.mId);
  }, [params?.mId]);

  const getMember = (id: any) => {
    setLoading(true);
    setLoaded(false);
    HttpService.postHeader("member_details", { id: id })
      .then(
        (result) => {
          setLoading(false);
          console.log(result);
          if (result.status === 1) {
            setMember(result.data);
          }
        },
        (error) => {}
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //fetch
  }; //doAjax

  const closeDelModal = () => {
    setDelModal({ ...del_modal, onopen: false });
  };
  const [del_modal, setDelModal] = React.useState<any>({
    onopen: false,
    onclose: closeDelModal,
    title: "Delete member",
  });

  let navigate = useNavigate();
  const togEdit = (i: any) => {
    return navigate(`/admin/members/e/${i.id}`);
  };

  const launchNew = (i: any) => {
    return navigate(`/admin/members/a/new`);
  };

  const delete_member = (item: any) => {
    setLoading(true);
    setLoaded(false);
    setDelModal({
      ...del_modal,
      loading: true,
      message: null,
      loading_message: "Working...",
    });
    HttpService.post({ id: item.id }, "remove_member")
      .then(
        (resp) => {
          if (resp.status === 1) {
            setModal({
              ...modal,
              onopen: true,
              severity: "success",
              message: resp.message,
            });
          }
          setTimeout(() => {
            setDelModal({
              ...del_modal,
              onopen: false,
              onclose: closeDelModal,
            });
            setModal({ ...modal, onopen: false, onclose: closeModal });
          }, 3000);
        },
        (error) => {
          setModal({
            ...modal,
            onopen: true,
            severity: "error",
            message: error.message,
          });
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //fetch
  };

  const closeModal = () => {
    setModal({ ...modal, onopen: false });
  };
  const [modal, setModal] = React.useState<any>({
    onopen: false,
    onclose: closeModal,
    title: "Edit member",
    loading_message: null,
    loading: false,
  });

  const activate_member = (item: any) => {
    setLoading(true);
    setLoaded(false);
    setDelModal({
      ...del_modal,
      loading: true,
      message: null,
      loading_message: "Working...",
    });
  };

  const launchDelete = (item: any) => {
    setDelModal({
      ...del_modal,
      id: item.id,
      loading_message: null,
      loading: false,
      message:
        "Are you sure you want to <strong><u>remove</u></strong> this member?",
      onopen: true,
      onclose: closeDelModal,
      onaccept: delete_member,
    });
  };
  const launchActivate = (item: any) => {
    const act = item.is_activated == "1" ? "deactivate" : "activate";
    setDelModal({
      ...del_modal,
      id: item.id,
      action: act,
      title: act + " member",
      message: `Are you sure you want to <strong><u>${act}</u></strong> this member?`,
      onopen: true,
      loading_message: null,
      loading: false,
      onclose: closeDelModal,
      onaccept: () => activate_member({ id: item.id, action: act }),
    });
  };

  const tabs = [
    { path: "feed", title: "Timeline" },
    { path: "articles", title: "Articles" },
    { path: "media", title: "Photos & Videos" },
    { path: "comments", title: "Comments" },
  ];
  const [tab, setTab] = React.useState<any>("info");
  const [default_tab, setDefTab] = React.useState<any>(tabs[0]);
  const page_tab = params.tab;

  React.useEffect(() => {
    setTab(params?.tab);
    //setDefTab(params?.tab);
  }, [params.tab]);

  const [isTogged, setIsTogged] = React.useState(false);
  const toggleNav = () => {
    const cur = isTogged;
    setIsTogged(!cur);
  };

  const doTab = (item: any) => {
    setTab(item?.path);
    setDefTab(item);
    toggleNav();
  };

  return (
    <React.Fragment>
      <section className="page-main">
        <div className="home-overlay"></div>
        <Header />
      </section>
      {loaded && (
        <>
          <div className="header-image-area"></div>

          <div className="container relative">
            <div className="profile-container">
              <div className="photo-side">
                <div className="pimg-placer">
                  <div
                    className="profile-largest"
                    style={{
                      background: `url(${
                        process.env.REACT_APP_SERVER_ROOT + member.thumb
                      })`,
                    }}
                  ></div>
                </div>

                <div className="bio-data-div">
                  <div className="mb10">
                    <div>
                      <i className="fas fa-phone pr10"></i>{" "}
                      {member?.phone || ""}
                    </div>
                  </div>
                  <div className="data-line">
                    <div>
                      <i className="fas fa-envelope pr10"></i>
                      {member?.email || ""}
                    </div>
                  </div>
                  <div className="data-line">
                    <div className="sub-text">Kindred</div>
                    <div>{member.kindred_name || ""}</div>
                  </div>
                  <div className="data-line">
                    <div className="sub-text">Quarter</div>
                    <div>{member.quarter_name || "--"}</div>
                  </div>

                  <div className="data-line">
                    <div className="sub-text">Residence</div>
                    <div> {member.location_type_title || "--"}</div>
                  </div>

                  <div className="data-line">
                    <div className="sub-text">State of residence</div>
                    <div>{member.state_of_residence || "--"}</div>
                  </div>
                </div>
              </div>
              {/* photo-side ends */}
              <div className="other-side">
                <div className="bar-line">
                  <div className="spacer">
                    <h1 style={{ lineHeight: "1" }}>{member?.name}</h1>
                    <div className="bio-summary">Software Engineers</div>
                  </div>
                  <span className="py10 flex align-items-center pl20">
                    <span className="pr5">
                      <LikeTemplate item={member} item_label={"profile"} />
                    </span>
                    <Tooltip title="Send Message">
                      <Button
                        size="large"
                        sx={{ px: "15px", minWidth: "20px" }}
                        variant="contained"
                        color="secondary"
                      >
                        <span className="flex align-items-center">
                          <MailOutlineOutlined sx={{ fontSize: "18px" }} />
                        </span>
                      </Button>
                    </Tooltip>
                  </span>
                </div>
                {/* barline ends */}
                <div className="bar-line">
                  <div className="flex flex-col">
                    <div className="creds">
                      <span className="icred">PHP</span>
                      <span className="icred">PHP</span>
                      <span className="icred">PHP</span>
                      <span className="icred">PHP</span>
                    </div>
                  </div>
                </div>
                {/* barline ends */}
                <div className="mb20 flex flex-row-resp align-items-center">
                  <div className={`nav-cover`}>
                    <span className="def-sub" onClick={toggleNav}>
                      <span className="spacer">
                        <i className={`fas ${default_tab.icon} pr5 txt-sm`}></i>
                        {default_tab.title}
                      </span>
                      <span className="mobile-nav">
                        <button onClick={toggleNav} className="mnav dark">
                          <i
                            className={`fas fa-${
                              !isTogged ? "chevron-down" : "chevron-up"
                            }`}
                          ></i>
                        </button>
                      </span>
                    </span>
                    <ul
                      className={`category-tab mini  ${
                        isTogged ? "flex" : "none"
                      }`}
                    >
                      {tabs.map((item, index) => (
                        <li key={item.title}>
                          <Link
                            to={`/members/e/${member.id}/${item.path}`}
                            onClick={() => doTab(item)}
                            className={item.path === page_tab ? "active" : ""}
                          >
                            {item.title}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                {/*tabs end */}
                <section className="body-page">
                  {tab === "info" && (
                    <section>
                      <div className="sectioner">
                        <h2>About {member?.name}</h2>
                        <Card sx={{ padding: "20px", marginTop: "10px" }}>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: decodeHtml(member?.bio),
                            }}
                          ></div>
                        </Card>
                      </div>
                    </section>
                  )}
                </section>
              </div>
            </div>
          </div>
          {/*container2*/}

          {/*container1*/}
          {/*container2*/}
        </>
      )}
    </React.Fragment>
  );
};

export default MembersDetail;
