import React from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { Tooltip } from "@mui/material";
const DesktopNav = (props: any) => {
  const {
    doLogout,
    isLogged,
    usr,
    event_categories,
    loading_event_categories,
    loaded_event_categories,
  } = props;

  return (
    <React.Fragment>
      <div className="dektop-nav">
        <ul className="header-navigation">
          <li className="nav-more-icon">
            <Link to="/about">About Us</Link>
            <ul>
              <li>
                <Link to="/about/history">History</Link>
              </li>
              <li>
                <Link to="/about/heritage">Heritage</Link>
              </li>
              <li>
                <Link to="/about/ichie-diokwu">Ichie Diokwu</Link>
              </li>
              <li>
                <Link to="/about/executives">Executives</Link>
              </li>
              <li>
                <Link to="/about/kindreds">Kindreds</Link>
              </li>
              <li>
                <Link to="/about/quarters">Quarters</Link>
              </li>
              <li>
                <Link to="/about/past-leaders">Past Leaders</Link>
              </li>
              <li>
                <Link to="/about/nze">Ndi Nze</Link>
              </li>
              <li>
                <Link to="/about/ebo-ani">Ndi Ichie Eboani</Link>
              </li>
              <li>
                <Link to="/about/ichie-dimechem">Ndi Ichie Dimechem</Link>
              </li>
              <li>
                <Link to="/about/committees">Committees</Link>
              </li>
              <li>
                <Link to="/about/constitution">Constitution</Link>
              </li>
            </ul>
          </li>

          <li className={loaded_event_categories ? "nav-more-icon" : ""}>
            <Link to="/events">Events</Link>

            {loaded_event_categories && event_categories && (
              <ul>
                {event_categories.map((item: any, index: number) => (
                  <li key={item.title}>
                    <Link to={`/events/${item?.slug}`}>{item?.title}</Link>
                  </li>
                ))}
              </ul>
            )}
          </li>
          <li>
            <Link to="/meetings">Meetings</Link>
          </li>

          <li>
            <Link to="/members">Members</Link>
          </li>
          <li className="nav-more-icon">
            <Link to="/gallery">Gallery</Link>
            <ul>
              <li>
                <Link to="/gallery/videos">Videos</Link>
              </li>
              <li>
                <Link to="/gallery/audios">Audios</Link>
              </li>
              <li>
                <Link to="/gallery/letters">Letters</Link>
              </li>
            </ul>
          </li>
        </ul>

        <span className="header-cta">
          {!isLogged && (
            <>
              <Tooltip title="Log In">
                <Link to="/login" className="login">
                  LOGIN
                </Link>
              </Tooltip>
              {/*  <Tooltip title="Join Now">
                <Link to="/register" className="register">
                  JOIN NOW
                </Link>
              </Tooltip> */}
            </>
          )}
          {isLogged && (
            <>
              <Tooltip title="Dashboard">
                <Link
                  to={
                    usr.role === "admin"
                      ? "/admin/dashboard"
                      : "/account/dashboard"
                  }
                  className="register"
                >
                  <i className="fas fa-dashboard"></i>
                </Link>
              </Tooltip>
              <Tooltip title="Log Out">
                <Link to="#" onClick={doLogout} className="logout">
                  <i className="fas fa-sign-out-alt"></i>
                </Link>
              </Tooltip>
            </>
          )}
        </span>
      </div>
    </React.Fragment>
  );
};

export default DesktopNav;
