import React from "react";
import GalleryLayout from "./GalleryLayout";
import PlaceHolder from "../../templates/PlaceHolder";
import HttpService from "../../../services/HttpService";

const GalleryList = (props: any) => {
  const [offset, setOffset] = React.useState<number>(0);
  const [gallery, setGallery] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [page_data, setPageDate] = React.useState<any>({});

  React.useEffect(() => {
    setPageDate({
      ...page_data,
      page_slug: "",
      page_title: "Gallery",
      page_summary: "All Dianaokwu Gallery",
    });
    listGallery(offset);
  }, []);

  const listGallery = (offset: number) => {
    setLoading(true);
    setLoaded(false);
    HttpService.post({ offset: offset, limit: 24, mode: "all" }, "list_gallery")
      .then(
        (result) => {
          console.log(result);
          if (result.status === 1) {
            let newRes = [...gallery, ...result.data];
            setGallery(result.data);
          }
        },
        (error) => {}
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //fetch
  }; //doAjax

  const loadMore = () => {
    const newOffset = offset + 20;
    setOffset(newOffset);
    listGallery(newOffset);
  };
  return (
    <React.Fragment>
      <GalleryLayout page_data={page_data}>
        {loaded && <div>====list</div>}
        {loading && <PlaceHolder type="articles" />}
        <div className="flex justify-content-center align-items-center py20">
          <button disabled={loading} className="more" onClick={loadMore}>
            {loading ? "Loading..." : "Load More"}
          </button>
        </div>
      </GalleryLayout>
    </React.Fragment>
  );
};
export default GalleryList;
