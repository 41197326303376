import React from "react";
import { Routes, Route, Link, useParams } from "react-router-dom";
import Card from "@mui/material/Card";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import SeoModule from "../../../services/SeoModule";
import useFetchPage from "../../../hooks/useFetchPage";
import * as processHtml from "../../../services/processHtml";
import { LinearProgress } from "@mui/material";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import PageIntro from "../PageIntro/PageIntro";
import SideLinks from "../../templates/SideLinks";
import HttpService from "../../../services/HttpService";
import History from "./History";
import Heritage from "./Heritage";
import Quarters from "./Quarters";
import Nze from "./Nze";
import IchieDimechem from "./IchieDimechem";
import PastLeaders from "./PastLeaders";
import Executives from "./Executives";
import Kindreds from "./Kindreds";
import IchieEboani from "./IchieEboani";
import IchieDiokwu from "./IchieDiokwu";
import Committees from "./Committees";
import Constitution from "./Constitution";

const About = () => {
  const { decodeHtml, truncateWord } = processHtml;
  const [loading_page, setLoading] = React.useState(false);
  const [loaded_page, setLoaded] = React.useState(false);
  const params: any = useParams();
  const page_route = params["*"];
  console.log(page_route);
  const get_obj = (links: any[]) => {
    let res = null;
    links.map(function (route) {
      if (route.path === page_route) {
        res = route;
      }
    });
    return res;
  };
  const links: any[] = [
    {
      path: "history",
      title: "History",
      route: () => {
        return <History />;
      },
      icon: "fa-history",
    },
    {
      path: "kindreds",
      title: "Kindreds",
      route: () => {
        return <Kindreds />;
      },
      icon: "fa-user-group",
    },
    {
      path: "quarters",
      title: "Quarters",
      route: () => {
        return <Quarters />;
      },
      icon: "fa-solid fa-circle-quarter-stroke",
    },
    {
      path: "ichie-diokwu",
      title: "Ichie Diokwu",
      route: () => {
        return <IchieDiokwu />;
      },
      icon: "fa-user-circle",
    },
    {
      path: "heritage",
      title: "Heritage",
      route: () => {
        return <Heritage />;
      },
      icon: "fa-cog",
    },
    {
      path: "executives",
      title: "Executives",
      route: () => {
        return <Executives />;
      },
      icon: "fa-user-tie",
    },
    {
      path: "past-leaders",
      title: "Past Leaders",
      route: () => {
        return <PastLeaders />;
      },
      icon: "fa-cog",
    },
    {
      path: "nze",
      title: "Ndi Nze",
      route: () => {
        return <Nze />;
      },
      icon: "fa-cog",
    },
    {
      path: "ebo-ani",
      title: "Ndi Ichie Eboani",
      route: () => {
        return <IchieEboani />;
      },
      icon: "fa-cog",
    },
    {
      path: "ichie-dimechem",
      title: "Ndi Ichie Dimechem",
      route: () => {
        return <IchieDimechem />;
      },
      icon: "fa-cog",
    },
    {
      path: "committees",
      title: "Committees",
      route: () => {
        return <Committees />;
      },
      icon: "fa-users",
    },
    {
      path: "constitution",
      title: "Constitution",
      route: () => {
        return <Constitution />;
      },
      icon: "fa-book",
    },
  ];

  type rts = {
    title: string;
    icon: string;
    path: string;
    route: object;
  };

  const obj_path: any = get_obj(links);
  let page = { title: "" };
  const [default_tab, setDefTab] = React.useState<rts | null>(obj_path);
  const [cur_tab, setTab] = React.useState<any>("");
  React.useEffect(() => {
    setTab(links[0].slug);
  }, []);

  const [isTogged, setIsTogged] = React.useState(false);
  const toggleNav = () => {
    const cur = isTogged;
    setIsTogged(!cur);
  };

  const doTab = (item: any) => {
    setTab(item?.path);
    setDefTab(item);
    toggleNav();
  };
  return (
    <React.Fragment>
      <SeoModule
        title={page?.title}
        description="Fintech: banking, crypto, investments"
        name="Reva"
        page_url={process.env.REACT_APP_SERVER_DOMAIN}
        page_image={process.env.REACT_APP_SERVER_DOMAIN + "images/logo.png"}
        type="Crypto & Investment"
      />
      <section className="page-main">
        <div className="home-overlay"></div>
        <Header />
      </section>
      <section className="container-wide py20">
        <div className="about-container">
          <span className="def-sub mb10" onClick={toggleNav}>
            <span className="spacer">
              <i className={`fas ${default_tab?.icon} pr5 txt-sm`}></i>
              {default_tab?.title}
            </span>
            <span className="mobile-nav">
              <button onClick={toggleNav} className="mnav dark">
                <i
                  className={`fas fa-${
                    !isTogged ? "chevron-down" : "chevron-up"
                  }`}
                ></i>
              </button>
            </span>
          </span>

          <div className={`page-side-nav ${isTogged ? "flex" : "none"}`}>
            <ul className={`about-nav`}>
              {links.map((item: any) => (
                <li key={item.path}>
                  <Link
                    to={item.path}
                    onClick={() => doTab(item)}
                    className={item.path === default_tab?.path ? "active" : ""}
                  >
                    <i className={`fas ${item.icon} pr10 txt-sm`}></i>
                    {item.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="main-about-content spacer">
            <Card sx={{ borderRadius: "6px", p: "0", flexGrow: "1" }}>
              <Routes>
                <Route path={""} element={<History />} />
                <Route path={"/"} element={<History />} />
                {links.map((item: any, index: number) => {
                  const ThisComponent = item.route();
                  return (
                    <Route
                      key={item.path}
                      path={`/${item.path}`}
                      element={<item.route />}
                    />
                  );
                })}

                {/*     <Route path="/:url" element={<AboutPage />} /> */}
              </Routes>
            </Card>
          </div>
        </div>
      </section>

      <Footer />
    </React.Fragment>
  );
};

export default About;
