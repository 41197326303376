import React from "react";
import { Link, NavLink } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import DurationPipe from "../../pipes/DurationPipe";
import * as processHtml from "../../services/processHtml";
import {
  ArrowCircleDownOutlined,
  ArrowDownwardOutlined,
  ArrowDropDownCircleOutlined,
  CommentOutlined,
  FavoriteBorderOutlined,
  Grid4x4,
  ListAltOutlined,
} from "@mui/icons-material";
import { Avatar, Collapse, Tooltip } from "@mui/material";
import EventShareTemplate from "./EventShareTemplate";
import DatePipe from "../../pipes/DatePipe";

const MeetingsListTemplate = (props: any) => {
  const { meetings, loading, loaded, max_grid } = props;
  const [list, setList] = React.useState<any[]>(meetings);
  const base_url = props.base_url ? "/" + props.base_url : "/";
  const { decodeHtml } = processHtml;
  const togView = (index: number, state: boolean) => {
    console.log(index, state);
    const mutd = [...list];
    const item = (mutd[index]["is_togged"] = !mutd[index]["is_togged"]);
    setList(mutd);
  };

  const ucfirst = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  return (
    <React.Fragment>
      <div className="">
        {loaded && (
          <>
            <Grid container spacing={2}>
              {list.map((item: any, index: number) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={max_grid ? max_grid : 3}
                  key={item.id}
                >
                  <Card>
                    <div className={"news-card"}>
                      <Link
                        className="thumb_pane"
                        to={`${base_url}meetings/e/${item.url}`}
                      >
                        {item.is_thumb && (
                          <img
                            src={process.env.REACT_APP_SERVER_ROOT + item.thumb}
                            alt={item.title}
                          />
                        )}
                        <span className="cat-span">
                          <Link to={`/meetings/${item.category_url}`}>
                            {item.category_title}{" "}
                          </Link>
                        </span>
                      </Link>

                      <div className="news_content_space">
                        <div className="flex flex-row align-items-center">
                          <div className="date-space spacer">
                            <DatePipe value={item.start_time * 1000} />
                          </div>

                          <span className="texr">
                            {!item.is_togged ? (
                              <Tooltip title="View Meeting detail">
                                <IconButton
                                  size="small"
                                  onClick={() => togView(index, true)}
                                >
                                  <i className="fas fa-chevron-down"></i>
                                </IconButton>
                              </Tooltip>
                            ) : (
                              <Tooltip title="Hide  Meeting detail">
                                <IconButton
                                  size="small"
                                  onClick={() => togView(index, false)}
                                >
                                  <i className="fas fa-chevron-up"></i>
                                </IconButton>
                              </Tooltip>
                            )}
                          </span>
                        </div>
                        <h3>
                          <NavLink to={`${base_url}meetings/e/${item.url}`}>
                            {item.title}
                          </NavLink>
                        </h3>
                        <div className="news-summary">
                          <div
                            className="my5"
                            dangerouslySetInnerHTML={{
                              __html: decodeHtml(item.description),
                            }}
                          ></div>
                          <Link to={`${base_url}meetings/e/${item.url}`}>
                            Read More &raquo;
                          </Link>
                        </div>
                        <Collapse in={item.is_togged}> </Collapse>
                      </div>

                      <div className="react-span px10">
                        <span className="spacer">
                          <IconButton>
                            <CommentOutlined />
                          </IconButton>
                          {item.comment_num}{" "}
                        </span>
                        <span>
                          <IconButton className={"react-btn share-btn"}>
                            <FavoriteBorderOutlined />
                          </IconButton>
                        </span>
                        <span>
                          <EventShareTemplate event={item} />
                        </span>
                      </div>
                    </div>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </>
        )}
      </div>
    </React.Fragment>
  );
};

export default React.memo(MeetingsListTemplate);
