import React from "react";
import { Link, NavLink } from "react-router-dom";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import MailOutline from "@mui/icons-material/MailOutline";
import PlaceHolder from "../../templates/PlaceHolder";
import HttpService from "../../../services/HttpService";
import DatePipe from "../../../pipes/DatePipe";
import { Breadcrumbs } from "@mui/material";
import Loading from "../../templates/Loading";

const Income = (props: any) => {
  const [incomes, setIncome] = React.useState<any>(null);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (!incomes) {
      doAjax();
    }
  }, []); //componentDidMount

  const doAjax = () => {
    setLoading(true);
    setLoaded(false);
    HttpService.postHeader("all_incomes", {})
      .then(
        (result) => {
          console.log(result);
          if (Array.isArray(result.data)) {
            setIncome(result.data);
          }
        },
        (error) => {
          setIncome([]);
        }
      )
      .finally(() => {
        setLoaded(true);
        setLoading(false);
      }); //fetch
  }; //doAjax

  const closeModal = () => setModal({ ...modal, onopen: false });
  const [modal, setModal] = React.useState<any>({
    onopen: false,
    onclose: closeModal,
  });
  const launchDetail = () => {
    setModal({ ...modal, onopen: true, onclose: closeModal });
  };

  return (
    <React.Fragment>
      <section>
        {loaded && (
          <List
            sx={{
              p: "0",
              m: "0",
              // selected and (selected + hover) states
              "&& .Mui-selected, && .Mui-selected:hover": {
                bgcolor: "#000000",
                "&, & .MuiListItemIcon-root": {
                  color: "#ffffff",
                },
              },
              // hover states
              "& .MuiListItemButton-root:hover": {
                bgcolor: "#dedede",
                "&, & .MuiListItemIcon-root": {
                  color: "#000000",
                },
              },
            }}
          >
            {incomes.map((item: any, index: number) => (
              <ListItem
                disablePadding
                button
                key={item.id}
                divider={true}
                component={NavLink}
                to={`/aacount/financials/incomes/p/${item.id}`}
                secondaryAction={
                  <>
                    <span>
                      <DatePipe
                        value={new Date(item.created_at).getTime() * 1000}
                      />
                    </span>
                  </>
                }
              >
                <ListItemButton>
                  <ListItemIcon>
                    <MailOutline />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography component={"h2"}>{item.subject}</Typography>{" "}
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        )}
        {loaded && incomes.length === 0 && (
          <div className="result-error">
            <span className="status-icon">
              <i className="fas fa-exclamation-triangle"></i>
            </span>
            <h3> No Income found!</h3>
          </div>
        )}
      </section>

      {loading && <Loading />}
    </React.Fragment>
  );
};

export default Income;
