import { Button } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import HttpService from "../../../services/HttpService";
import Header from "../../Header/Header";
import * as processHtml from "../../../services/processHtml";
import useFetchHomepageDetail from "../../../hooks/useFetchHomepageDetail";
const HomeMain = () => {
  const { decodeHtml } = processHtml;

  const [tab, setTab] = React.useState<number>(0);
  const styles = {
    backgroundImage:
      tab === 0 ? "url(/images/main-home.webp)" : "url(/images/girly.png)",

    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  };

  const tabs: any[] = [
    { id: 0, title: "HIRE US" },
    { id: 1, title: "LEARN SKILLS" },
  ];

  let { homepage_detail, loading_homepage, loaded_homepage } =
    useFetchHomepageDetail({ slug: "intro-message" });
  return (
    <React.Fragment>
      {/*  style={styles} */}
      <section className="home-main home-cover">
        <div className="home-overlay"></div>
        <Header base_page="home" />

        <div className="home-content">
          <div className="home-intro-div">
            <h1>
              {/* <span className="em">This is</span>{" "}
              <span className="colored">Dianaokwu</span>*/}
              {homepage_detail ? (
                <span
                  dangerouslySetInnerHTML={{
                    __html: decodeHtml(homepage_detail?.section_title),
                  }}
                ></span>
              ) : (
                <>
                  <span className="em">This is</span>{" "}
                  <span className="colored">Dianaokwu</span>
                </>
              )}
              <br />{" "}
              <em>
                {homepage_detail ? (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: decodeHtml(homepage_detail?.section_sub_title),
                    }}
                  ></span>
                ) : (
                  <span>Diokwu Ọ melụ ife atụ aja!</span>
                )}
              </em>
            </h1>

            {homepage_detail ? (
              <div
                className="home-summary"
                dangerouslySetInnerHTML={{
                  __html: decodeHtml(homepage_detail?.section_content),
                }}
              ></div>
            ) : (
              <div className="home-summary">Diokwu Obodo Dike!</div>
            )}
            <div className="hcta">
              <Link to="/about/history">OUR HISTORY</Link>
            </div>
          </div>
          <div className="illustration-container">
            
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default React.memo(HomeMain);
