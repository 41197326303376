import { calendarData } from "../data/calendar-data";
import dateProcess from "./dateProcess";
const prep_date = (raw_data: any) => {
  if (raw_data) {
    let load: any[] = [[], [], [], [], [], [], []];
    let ord = 0;
    let ara = raw_data.calendar_days;
    for (let i = 0; i < ara.length; i++) {
      let dindex = i % 7;
      if (i > 0 && dindex === 0) {
        ord++;
      }
      load[ord].push(ara[i]);
    }
    let dump: any[] = [];
    load.forEach((el) => {
      if (el.length > 0) {
        dump.push(el);
      }
    });

    const final = { ...raw_data, calendar_days: dump };
    return final;
  } else {
    return false;
  }
};

const FetchDate = (dtn: any, lang: any) => {
  let curDate = "";
  const setDate = (data: any) => {
    curDate = data;
  };
  //console.log("date to process:: ", dtn);
  let response_object: any = {};
  const dateArrays: any = calendarData();
  let calendar_array: any = [];
  let languageLabel = "English";
  const langs: any = ["english", "igbo", "hausa", "yoruba"];
  let language =
    lang !== false && lang != "" && langs.includes(lang) ? lang : "english";
  //console.log("language to use: ", language);
  languageLabel = language.toUpperCase();
  let dt = dtn !== false ? new Date(dtn) : new Date();
  let today_unix = dt.getTime();
  let day_seconds = 60 * 60 * 24 * 1000;
  let day_since_70 = today_unix / day_seconds;
  let year_since_70 = day_since_70 / 365.25;
  let day_offset = dt.getDay() + 1;
  let today = dt.getDate();
  let xdate = dt,
    y = xdate.getFullYear(),
    m = xdate.getMonth();
  let cur_month_first_day = new Date(y, m, 1);
  response_object.next_month_first_day = new Date(y, m + 1, 1);
  let cur_month_last_day = new Date(y, m + 1, 0);
  response_object.cur_month_label =
    dateArrays.naija_months[language][m]["month"];
  response_object.cur_year_label = xdate.getFullYear();
  let last_month_last_day = new Date(y, m, 0);
  response_object.last_month_last_day = last_month_last_day;
  let total_days_in_last_month = new Date(y, m, 0).getDate();
  let cur_month_first_day_offset = cur_month_first_day.getDay();
  let cur_offset = cur_month_first_day.getDay();
  let cal_days_in_month = new Date(y, m + 1, 0).getDate();
  response_object.week_days = dateArrays.naija_weekdays[language];

  let cell = [] as any;

  let market = "";
  let loopi = cur_offset > 4 ? 42 : 35;
  //let loopi = 42;
  let todays_count = 0;
  let cur_i = -1;
  for (let i = 0; i < loopi; i++) {
    let date_obj = {} as any;
    date_obj.index = i;
    if (i >= cur_offset && todays_count < cal_days_in_month) {
      todays_count++;
      date_obj.todays_count = todays_count;
      date_obj.todays_day_num = todays_count;
      date_obj.todays_date = m + 1 + "/" + todays_count + "/" + y;
      date_obj.disabled = "";
      if (todays_count > cal_days_in_month) {
        todays_count = 1;
      }
      //console.log(objx)
    } else if (i < cur_offset) {
      let difx = cur_offset - (i + 1);
      date_obj.todays_count = total_days_in_last_month - difx;
      date_obj.todays_date = "unknown-past-month";

      //date_obj.todays_date = m + 1 + "/" + total_days_in_last_month - difx + "/" + y;
      date_obj.disabled = "disabled";
    } else if (date_obj.index > cal_days_in_month) {
      date_obj.todays_count = todays_count - cal_days_in_month + 1;
      date_obj.todays_date = "unknown-future-month";
      date_obj.disabled = "disabled";
      todays_count++;
    }
    date_obj.todays_class = today == todays_count ? "today_active" : "";
    let dt_arr = dateProcess(date_obj.todays_date, language);
    let objx = { ...date_obj, ...dt_arr };

    if (today == todays_count) {
      cur_i = i;
    }
    cell.push(objx);
  }
  calendar_array = cell;
  if (cur_i !== -1) {
    setDate(cell[cur_i]);
  }

  response_object.cur_lang = language;
  response_object.cur_date = dt;
  response_object.calendar_days = cell;
  //console.log(response_object);
  return prep_date(response_object);
}; //FetchDate

export default FetchDate;
