import React from "react";

const Loading = (props?: any) => {
  const { loading_text } = props;
  return (
    <div className="result-error">
      <span className="status-icon">
        <i className="fas fa-circle fa-beat"></i>
      </span>
      <h3>{loading_text ? loading_text + "..." : "Loading..."}</h3>
    </div>
  );
};

export default React.memo(Loading);
