import React from "react";
import { Routes, Route, Link, useParams } from "react-router-dom";
import { Breadcrumbs, Card } from "@mui/material";
import All from "./All";
import Paid from "./Paid";
import UnPaid from "./UnPaid";
import PayNow from "./PayNow";
import AuthService from "../../../services/AuthService";
import HttpService from "../../../services/HttpService";
const Clearance = () => {
  const params = useParams();
  const isParam = params["*"] ? true : false;
  let urpath = params["*"];

  console.log("urpath:", urpath);
  const get_obj = (links: any[]) => {
    let res = null;
    links.map(function (route) {
      if (route.path === urpath) {
        res = route;
      }
    });
    return res;
  };
  React.useEffect(() => {
    //getMember(params.*);
  }, [urpath]);

  const tabs = [
    { path: "all", title: "All", icon: "" },
    { path: "unpaid", title: "Unpaid", icon: "" },
    { path: "paid", title: "Paid", icon: "" },
    { path: "pay-now", title: "Pay Clearance", icon: "" },
  ];
  type rts = {
    title: string;
    icon?: string;
    path: string;
    route?: object;
  };
  const obj_path: any = get_obj(tabs);
  let defor = urpath && urpath.length > 0 ? obj_path : tabs[0];
  console.log(obj_path, typeof urpath, defor);
  const [default_tab, setDefTab] = React.useState<rts | null>(defor);

  const [tab, setTab] = React.useState<any>("");

  React.useEffect(() => {
    setTab(defor.path);
  }, []);

  const [isTogged, setIsTogged] = React.useState(false);
  const toggleNav = () => {
    const cur = isTogged;
    setIsTogged(!cur);
  };

  const doTab = (item: any) => {
    setTab(item?.path);
    setDefTab(item);
    toggleNav();
  };

  const [clearance, setClear] = React.useState<any>(null);
  const [all, setAll] = React.useState<any>(null);
  const [paid, setPaid] = React.useState<any>(null);
  const [unpaid, setUnPaid] = React.useState<any>(null);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const usr = AuthService.getCurrentUser();
  React.useEffect(() => {
    if (!clearance) {
      doAjax();
    }
  }, []); //componentDidMount

  const doAjax = () => {
    setLoading(true);
    setLoaded(false);
    HttpService.postHeader("list_clearance", { uid: usr.id })
      .then(
        (result) => {
          if (result.status === 1) {
            let clear = result.data;
            console.log(clear);
            setClear(clear);
            setAll(clear.all);
            setPaid(clear.paid);
            setUnPaid(clear.unpaid);
          }
        },
        (error) => {
          setClear([]);
          setAll([]);
          setPaid([]);
          setUnPaid([]);
        }
      )
      .finally(() => {
        setLoaded(true);
        setLoading(false);
      }); //fetch
  }; //doAjax

  return (
    <React.Fragment>
      <section className="account-page-container">
        <Card>
          <div className="page-topper">
            <Breadcrumbs>
              <Link to="/account">Dashboard</Link>
              <span>Clearance</span>
            </Breadcrumbs>
            <h1>{default_tab?.title}</h1>
          </div>
          <section className="page-detail-container pxy10">
            <div className={`nav-cover`}>
              <span className="def-sub" onClick={toggleNav}>
                <span className="spacer">
                  <i className={`fas ${default_tab?.icon} pr5 txt-sm`}></i>
                  {default_tab?.title}
                </span>
                <span className="mobile-nav">
                  <button onClick={toggleNav} className="mnav dark">
                    <i
                      className={`fas fa-${
                        !isTogged ? "chevron-down" : "chevron-up"
                      }`}
                    ></i>
                  </button>
                </span>
              </span>
              <ul
                className={`category-tab mini  ${isTogged ? "flex" : "none"}`}
              >
                {tabs.map((item, index) => (
                  <li key={item.title}>
                    <Link
                      to={`/account/clearance/${item.path}`}
                      onClick={() => doTab(item)}
                      className={item.path === urpath ? "active" : ""}
                    >
                      {item.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            {loaded && (
              <section className="mt20">
                {tab === "all" && (
                  <All item={all} loaded={loaded} loading={loading} />
                )}
                {tab === "paid" && (
                  <Paid item={paid} loaded={loaded} loading={loading} />
                )}
                {tab === "unpaid" && (
                  <UnPaid item={unpaid} loaded={loaded} loading={loading} />
                )}
                {tab === "pay-now" && (
                  <PayNow item={[]} loaded={loaded} loading={loading} />
                )}
              </section>
            )}
          </section>
        </Card>
      </section>
    </React.Fragment>
  );
};

export default Clearance;
