import React from "react";
import { Link } from "react-router-dom";
import useFetchPage from "../../../hooks/useFetchPage";
import * as processHtml from "../../../services/processHtml";
import { LinearProgress } from "@mui/material";

const Committees = () => {
  const { decodeHtml, truncateWord } = processHtml;
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { page, loaded_page, loading_page } = useFetchPage({
    slug: "committees",
  });
  return (
    <React.Fragment>
      <section>
        <div className="bga pxy20">
          <h1>{page?.menu_title || "Committees"}</h1>
        </div>
        <div className="pxy20">
          <div>
            {loading_page && <LinearProgress />}
            {!loading_page && (
              <div
                dangerouslySetInnerHTML={{
                  __html: decodeHtml(page.description),
                }}
              ></div>
            )}
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Committees;
