import React from "react";
import Collapse from "@mui/material/Collapse";
import { Link } from "react-router-dom";

const MobileNav = (props: any) => {
  const {
    doLogout,
    isLogged,
    isTogged,
    setIsTogged,
    event_categories,
    loading_event_categories,
    loaded_event_categories,
  } = props;
  const [togged, setTogged] = React.useState<any[]>([false, false]);
  const setTog = (index: number) => {
    const nr = [...togged];
    nr[index] = !nr[index];
    setTogged(nr);
  };
  return (
    <ul className="mobile-header-nav">
      <li>
        <Link onClick={() => setIsTogged(!isTogged)} to="/">
          Home
        </Link>
      </li>
      <li className="icon">
        <div className="list-fig">
          <Link className="spacer" to="/about" onClick={() => setTog(1)}>
            About Us
          </Link>
          <span className="dropper">
            <a onClick={() => setTog(1)}>
              <i
                className={`fas ${
                  togged[1] ? "fa-chevron-up" : "fa-chevron-down"
                }`}
              ></i>
            </a>
          </span>
        </div>
        <Collapse in={togged[1]}>
          <ul onClick={() => setIsTogged(!isTogged)}>
            <li>
              <Link to="/about/history">History</Link>
            </li>
            <li>
              <Link to="/about/heritage">Heritage</Link>
            </li>
            <li>
              <Link to="/about/ichie-diokwu">Ichie Diokwu</Link>
            </li>
            <li>
              <Link to="/about/executives">Executives</Link>
            </li>
            <li>
              <Link to="/about/kindreds">Kindreds</Link>
            </li>
            <li>
              <Link to="/about/quarters">Quarters</Link>
            </li>
            <li>
              <Link to="/about/past-leaders">Past Leaders</Link>
            </li>
            <li>
              <Link to="/about/nze">Ndi Nze</Link>
            </li>
            <li>
              <Link to="/about/ebo-ani">Ndi Ichie Eboani</Link>
            </li>
            <li>
              <Link to="/about/ichie-dimechem">Ndi Ichie Dimechem</Link>
            </li>
            <li>
              <Link to="/about/committees">Committees</Link>
            </li>
            <li>
              <Link to="/about/constitution">Constitution</Link>
            </li>
          </ul>
        </Collapse>
      </li>
      <li className="icon">
        <div className="list-fig">
          <Link className="spacer" to="/events" onClick={() => setTog(1)}>
            Events
          </Link>
          <span className="dropper">
            <a onClick={() => setTog(2)}>
              <i
                className={`fas ${
                  togged[2] ? "fa-chevron-up" : "fa-chevron-down"
                }`}
              ></i>
            </a>
          </span>
        </div>
        <Collapse in={togged[2]}>
          {loaded_event_categories && event_categories && (
            <ul onClick={() => setIsTogged(!isTogged)}>
              {event_categories.map((item: any, index: number) => (
                <li key={item.title}>
                  <Link to={`/events/${item?.slug}`}>{item?.title}</Link>
                </li>
              ))}
            </ul>
          )}
        </Collapse>
      </li>
      <li>
        <Link onClick={() => setIsTogged(!isTogged)} to="/meetings">
          Meetings
        </Link>
      </li>

      <li>
        <Link onClick={() => setIsTogged(!isTogged)} to="/members">
          Members
        </Link>
      </li>
      <li>
        <Link onClick={() => setIsTogged(!isTogged)} to="/gallery">
          Gallery
        </Link>
      </li>
      <li>
        <Link onClick={doLogout} to="#">
          Log Out
        </Link>
      </li>
    </ul>
  );
};

export default MobileNav;
