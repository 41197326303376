import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import { Card, Divider } from "@mui/material";

const PlaceHolder = (props?: any) => {
  const vals = props?.type;
  const max_grid = props?.max_grid;
  const row_count = props?.row_count ? props?.row_count : 10;
  console.log(" PlaceHolder Renders::", row_count);
  return (
    <>
      {vals === "list" && (
        <div>
          {new Array(row_count)
            .fill(0, row_count)
            .map((item: any, index: number) => {
              console.log("tu:", item);
              return (
                <Box
                  sx={{
                    display: "flex",
                    marginBottom: "10px",
                    px: "15px",
                    marginTop: "5px",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  key={index + item}
                >
                  <Box sx={{ flexGrow: 0 }}>
                    <>
                      <Skeleton variant="circular" width={40} height={40} />
                    </>
                  </Box>
                  <Box sx={{ flexGrow: 1, marginLeft: "5px" }}>
                    <span>
                      <Skeleton variant="text" sx={{ fontSize: "1.2rem" }} />
                    </span>
                  </Box>
                  <Box sx={{ display: "flex", flexGrow: 0, marginLeft: "6px" }}>
                    <Skeleton
                      variant="text"
                      sx={{ fontSize: "0.8rem", mr: "3px", width: "20px" }}
                    />
                    <Skeleton
                      variant="text"
                      sx={{ fontSize: "0.8rem", mr: "3px", width: "20px" }}
                    />
                    <Skeleton
                      variant="text"
                      sx={{ fontSize: "0.8rem", ml: "2px", width: "20px" }}
                    />
                  </Box>
                </Box>
              );
            })}
        </div>
      )}

      {vals === "gallery-content" && (
        <Box
          sx={{
            display: "flex",
            margin: "20px",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Skeleton
            sx={{ borderRadius: "4px", marginBottom: "7px" }}
            variant="rectangular"
            width={"100%"}
            height={25}
          />
          <Skeleton
            sx={{ borderRadius: "4px", marginBottom: "15px" }}
            variant="rectangular"
            width={"100%"}
            height={14}
          />

          <Skeleton
            sx={{ borderRadius: "4px" }}
            variant="rectangular"
            width={"100%"}
            height={320}
          />
        </Box>
      )}

      {vals === "profile" && (
        <Box
          sx={{
            display: "flex",
          }}
        >
          <Box
            sx={{
              display: "flex",
              minHeight: "400px",
              marginRight: "6px",
              maxWidth: "40%",
            }}
          >
            <Skeleton
              sx={{ borderRadius: "5px" }}
              variant="rectangular"
              width={"400px"}
              height={"100%"}
            />
          </Box>
          <Box sx={{ flexGrow: 1, paddingLeft: "35px" }}>
            <Skeleton variant="text" sx={{ fontSize: "2rem" }} />
            <Divider />
            <Skeleton variant="text" sx={{ fontSize: "2rem" }} />
            <Divider />
            <Skeleton variant="text" sx={{ fontSize: "2rem" }} />
            <Divider />
            <Skeleton variant="text" sx={{ fontSize: "2rem" }} />
            <Divider />
            <Skeleton variant="text" sx={{ fontSize: "2rem" }} />
            <Divider />
            <Skeleton variant="text" sx={{ fontSize: "2rem" }} />
          </Box>
        </Box>
      )}
      {vals === "text-line" && (
        <Skeleton
          sx={{ borderRadius: "2px" }}
          variant="rectangular"
          width={"100%"}
          height={25}
        />
      )}
      {vals === "articles" && (
        <Grid container spacing={2}>
          {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((item: any) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={max_grid ? max_grid : 3}
              key={item}
            >
              <Card>
                <div className={"news-card"}>
                  <div className="thumb_pane">
                    <Skeleton
                      sx={{ borderRadius: "4px" }}
                      variant="rectangular"
                      width={"100%"}
                      height={220}
                    />
                    <div className="cat-span">
                      <Skeleton
                        variant="rectangular"
                        width={"20%"}
                        height={30}
                      />
                    </div>
                  </div>
                  <div className="news_content_space">
                    <div className="flex align-items-center mb5">
                      <Skeleton
                        sx={{ borderRadius: "4px" }}
                        variant="rectangular"
                        width={"60px"}
                        height={12}
                      />{" "}
                      {" - "}{" "}
                      <Skeleton
                        sx={{ borderRadius: "4px" }}
                        variant="rectangular"
                        width={"60px"}
                        height={11}
                      />
                    </div>
                    <Skeleton
                      sx={{ borderRadius: "4px", marginBottom: "4px" }}
                      variant="rectangular"
                      width={"95%"}
                      height={16}
                    />
                    <Skeleton
                      sx={{ borderRadius: "4px", marginBottom: "3px" }}
                      variant="rectangular"
                      width={"70%"}
                      height={16}
                    />
                  </div>
                  <div className="react-span px10">
                    <Skeleton
                      sx={{ borderRadius: "4px", marginBottom: "5px" }}
                      variant="rectangular"
                      width={"25px"}
                      height={28}
                    />
                    <span className="spacer"></span>
                    <Skeleton
                      sx={{ borderRadius: "4px", marginBottom: "5px" }}
                      variant="rectangular"
                      width={"25px"}
                      height={28}
                    />
                  </div>
                </div>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}

      {vals === "users" && (
        <div className="pxy20">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <Skeleton
                sx={{ borderRadius: "4px" }}
                variant="rectangular"
                width={"100%"}
                height={90}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Skeleton
                sx={{ borderRadius: "4px" }}
                variant="rectangular"
                width={"100%"}
                height={90}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Skeleton
                sx={{ borderRadius: "4px" }}
                variant="rectangular"
                width={"100%"}
                height={90}
              />
            </Grid>
          </Grid>
        </div>
      )}

      {vals === "property-detail" && (
        <div className="pxy20">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={8} md={8}>
              <Skeleton
                variant="text"
                sx={{ fontSize: "1.6rem", marginBottom: "10px" }}
              />
              <Skeleton
                sx={{ borderRadius: "4px" }}
                variant="rectangular"
                width={"100%"}
                height={400}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Skeleton
                variant="text"
                sx={{ fontSize: "1.2rem", marginBottom: "5px" }}
              />
              <Skeleton
                variant="text"
                sx={{ fontSize: "1.2rem", marginBottom: "5px" }}
              />
              <Skeleton
                variant="text"
                sx={{ fontSize: "1.2rem", marginBottom: "5px" }}
              />
              <Skeleton
                variant="text"
                sx={{ fontSize: "1.2rem", marginBottom: "5px" }}
              />
              <Skeleton
                variant="text"
                sx={{ fontSize: "1.2rem", marginBottom: "5px" }}
              />
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
};

export default React.memo(PlaceHolder);
