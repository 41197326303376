import React from "react";
import { Grid } from "@mui/material";
import * as processHtml from "../../../services/processHtml";
import useFetchHomepageDetail from "../../../hooks/useFetchHomepageDetail";

//

const HomeChairmanIntro = () => {
  const [home, setHome] = React.useState<any>({});
  React.useEffect(() => {}, [home]);
  const { decodeHtml } = processHtml;
  let { homepage_detail, loading_homepage, loaded_homepage } =
    useFetchHomepageDetail({ slug: "chairman-message" });
  return (
    <React.Fragment>
      <div className="pg-intro-area">
        <div className="dark-overlay"></div>
        <div className="container">
          <div className="container-pad-v">
            <div className="w-50-resp margin-auto text-center">
              <h1 className="flex flex-col">
                {homepage_detail ? (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: decodeHtml(homepage_detail?.title),
                    }}
                  ></span>
                ) : (
                  <span>
                    Message from our <i>ebulient</i> Chairman
                  </span>
                )}
                <span className="ipo my20">WELCOME MESSAGE</span>
              </h1>
            </div>

            <div className="w-80-resp margin-auto">
              <div className="testimony-space">
                <div className="testimony-cover">
                  <div className="testimony-overlay"></div>
                  <span className="testimony-overlay-quote">
                    <i className="fa-solid fa-quote-right"></i>
                  </span>
                  <div className="testimony-content">
                    {homepage_detail ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: decodeHtml(homepage_detail?.section_content),
                        }}
                      ></div>
                    ) : (
                      <div>
                        Once you experience our culture, you’re sure to
                        understand what makes us so special. It’s a great way
                        for you to make use of all the wonderful benefits of
                        being a part of our communities
                      </div>
                    )}

                    <div className="pg-title">
                      - &nbsp;{" "}
                      {homepage_detail ? (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: decodeHtml(homepage_detail?.section_title),
                          }}
                        ></span>
                      ) : (
                        <span>Our Chairman</span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="spacer profile-imager">
                  <div className="profiler">
                    <div
                      className="profile-largest bg-white"
                      style={{ backgroundImage: `url(${"/images/pg.jpg"})` }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default HomeChairmanIntro;
