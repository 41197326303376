import React from "react";
import { Routes, Route } from "react-router-dom";
import MembersDetail from "./MembersDetail";
import MembersList from "./MembersList";
import Footer from "../../Footer/Footer";
import MembersKindred from "./MembersKindred";
const Members = () => {
  return (
    <React.Fragment>
      <section className="bg-diff">
        <Routes>
          <Route path="" element={<MembersList />} />
          <Route path="/" element={<MembersList />} />
          <Route path="/e/:mId" element={<MembersDetail />} />
          <Route path="/e/:mId/:tab" element={<MembersDetail />} />
          <Route path="/:kId" element={<MembersKindred />} />
        </Routes>
      </section>
      <Footer />
    </React.Fragment>
  );
};

export default Members;
