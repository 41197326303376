import React from "react";
import * as processHtml from "../../../services/processHtml";
import Edit from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import ButtonGroup from "@mui/material/ButtonGroup";
import DatePipe from "../../../pipes/DatePipe";
import ListItemButton from "@mui/material/ListItemButton";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import AuthService from "../../../services/AuthService";

const EventComments = (props: any) => {
  const cur_user = AuthService.getCurrentUser();
  console.log("CUR_USER::: ", cur_user);
  const {
    data,
    loading,
    loaded,
    is_admin,
    edit_action,
    delete_action,
    platform,
  } = props;
  const isAdmin = cur_user?.role === "admin" ? true : false;

  const { decodeHtml } = processHtml;
  if (platform === "detail_page") {
    return (
      <React.Fragment>
        {loading && <>Loading comments...</>}
        {data && (
          <section>
            {data.map((item: any, index: number) => (
              <div className="comment-cover" key={index}>
                <ListItem disablePadding divider={true}>
                  <ListItemButton disableRipple>
                    <ListItemAvatar>
                      <Avatar
                        variant="circular"
                        sx={{
                          width: 40,
                          height: 40,
                          mr: "10px",
                        }}
                        alt={`${item.name} `}
                        src={
                          item.thumb
                            ? process.env.REACT_APP_SERVER_ROOT + item.thumb
                            : "{`/images/logo.png`}"
                        }
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <h4 style={{ lineHeight: "1.2" }}>{item.name}</h4>
                      }
                      secondary={
                        <span className="date-span">
                          <i className="fas fa-clock"></i>{" "}
                          <DatePipe value={item.create_date * 1000} />
                        </span>
                      }
                    ></ListItemText>
                  </ListItemButton>
                </ListItem>
                <div className="comment-body">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: decodeHtml(item.comment),
                    }}
                  ></div>
                </div>
              </div>
            ))}
          </section>
        )}
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <section>
          <>
            {data.map((item: any, index: number) => (
              <div className="comment-cover" key={index}>
                {cur_user && item.email === cur_user["email"] && (
                  <>
                    <ListItem disablePadding divider={true}>
                      <ListItemButton>
                        <ListItemAvatar>
                          <Avatar
                            variant="circular"
                            sx={{
                              width: 60,
                              height: 60,
                              mr: "10px",
                              borderRadius: "8px",
                            }}
                            alt={`${item.name} `}
                            src={
                              item.photo
                                ? process.env.REACT_APP_SERVER_FILES_DOMAIN +
                                  item.photo
                                : "{`/images/logo.png`}"
                            }
                          />
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <h4 style={{ lineHeight: "1.2" }}>{item.name}</h4>
                          }
                          secondary={
                            <span className="date-span">
                              <i className="fas fa-clock"></i>{" "}
                              <DatePipe value={item.create_date * 1000} />
                            </span>
                          }
                        ></ListItemText>

                        <ButtonGroup
                          variant="outlined"
                          aria-label="outlined button group"
                        >
                          {(cur_user.email === item.email ||
                            cur_user.is_admin) && (
                            <>
                              <Tooltip title="Delete Comment">
                                <IconButton
                                  aria-label="delete"
                                  onClick={() =>
                                    delete_action({
                                      id: item.id,
                                      action: "delete",
                                    })
                                  }
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Edit Comment">
                                <IconButton
                                  aria-label="edit"
                                  onClick={() => edit_action(item.id)}
                                >
                                  <Edit />
                                </IconButton>
                              </Tooltip>
                            </>
                          )}
                        </ButtonGroup>
                      </ListItemButton>
                    </ListItem>
                    <div className="comment-body">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: decodeHtml(item.comment),
                        }}
                      ></div>
                    </div>{" "}
                  </>
                )}
              </div>
            ))}
          </>
        </section>
      </React.Fragment>
    );
  }
};

export default EventComments;
