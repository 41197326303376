import React from "react";
import { Link } from "react-router-dom";
import useFetchPage from "../../../hooks/useFetchPage";
import * as processHtml from "../../../services/processHtml";
import { Grid, LinearProgress } from "@mui/material";
import useFetchKindreds from "../../../hooks/useFetchKindreds";

const Kindreds = () => {
  const { decodeHtml, truncateWord } = processHtml;
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { page, loaded_page, loading_page } = useFetchPage({
    slug: "kindreds",
  });

  const { kindreds, loading_kindreds, loaded_kindreds } = useFetchKindreds();

  return (
    <React.Fragment>
      <section>
        <div className="bga pxy20">
          <h1>{page?.menu_title || "Kindreds"}</h1>
        </div>
        <div className="pxy20">
          <div>
            {loading_page && <LinearProgress />}
            {!loading_page && (
              <div
                dangerouslySetInnerHTML={{
                  __html: decodeHtml(page.description),
                }}
              ></div>
            )}
          </div>
        </div>

        <section className="pxy20">
          <Grid container spacing={2}>
            {kindreds.map((item) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                key={item.slug}
                sx={{ p: "10px" }}
              >
                <div className="feature-container">
                  <div className="feature-icon">
                    <img src={`${item.icon}`} alt={item.name} />
                  </div>
                  <div className="feature-text"> {item.name}</div>
                </div>
              </Grid>
            ))}
          </Grid>
        </section>
        {loading_kindreds && (
          <div className="pxy20">
            <LinearProgress />
            <div className="py20">Loading Kindred List</div>
          </div>
        )}
      </section>
    </React.Fragment>
  );
};

export default Kindreds;
