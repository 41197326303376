import React from "react";
import { Link, useParams } from "react-router-dom";
import { Breadcrumbs, Card, Grid, LinearProgress } from "@mui/material";
import Button from "@mui/material/Button";
import { DefaultEditor } from "react-simple-wysiwyg";
import HttpService from "../../../services/HttpService";
import CustomModal from "../../templates/CustomModal";
import useFetchHomepageDetail from "../../../hooks/useFetchHomepageDetail";

const Edit = () => {
  const params = useParams();
  const isParam = params.slug ? true : false;
  let [loading, setLoading] = React.useState<any>(false);
  let [loaded, setLoaded] = React.useState<any>(false);
  let { homepage_detail, loading_homepage, loaded_homepage } =
    useFetchHomepageDetail({ slug: params.slug });

  const [title, setTitle] = React.useState<any>("");
  const [section_title, setSectionTitle] = React.useState<any>("");
  const [section_sub_title, setSectionSubTitle] = React.useState<any>("");
  const [section_content, setSectionContent] = React.useState<any>("");
  let [id, setId] = React.useState<any>(0);

  React.useEffect(() => {
    if (loaded_homepage && homepage_detail) {
      setTitle(homepage_detail.title);
      setSectionTitle(homepage_detail.section_title);
      setSectionSubTitle(homepage_detail.section_sub_title);
      setSectionContent(homepage_detail.section_content);
      setId(homepage_detail.id);
      setPreview(
        process.env.REACT_APP_SERVER_ROOT + homepage_detail.section_photo
      );
    }
  }, [homepage_detail, loaded_homepage]);

  const onHtmlChange = (e: any) => {
    setSectionContent(e.target.value);
  };

  const [file, setFile] = React.useState<any>(null);
  const [new_file, setNewFile] = React.useState<any>(0);
  const [preview_image, setPreview] = React.useState<any>(null);
  const handleFileChange = (e: any) => {
    const formData = new FormData();
    let fname = e.target.title;
    let flx = e.target.files[0];
    formData.append("file", flx);
    console.log(flx, formData);
    setFile(flx);
    console.log(flx);
    setPreview(URL.createObjectURL(flx)); // Would see a path?
    setNewFile(1);
  };
  const closeModal = () => {
    setModal({ ...modal, onopen: false });
  };
  const [modal, setModal] = React.useState<any>({
    onopen: false,
    onclose: closeModal,
    title: "New homepage",
  });

  const handleSubmit = () => {
    console.log("SUBMITTING");
    setLoading(true);
    setLoaded(false);

    const formData = new FormData();
    formData.append("page", "admin");
    formData.append("id", id);
    formData.append("title", title);
    formData.append("section_title", section_title);
    formData.append("section_sub_title", section_sub_title);
    formData.append("banner", file);
    formData.append("section_content", section_content);

    HttpService.postFormHeader("edit_homepage", formData)
      .then((resp) => {
        console.log(resp);
        const severity =
          resp.status === 1
            ? "success"
            : resp.status === 0
            ? "error"
            : resp.status === 44
            ? "error"
            : "info";
        setModal({
          ...modal,
          onopen: true,
          message: resp.message,
          severity: severity,
        });
        if (resp.status === 1) {
        }
      })
      .catch((err) => {
        console.log(err);
        setModal({
          ...modal,
          onopen: true,
          severity: "error",
          message: err.message,
        });
      })
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      });
  };
  return (
    <div className="account-page-container">
      {loaded_homepage && (
        <Card>
          <div className="page-topper">
            <Breadcrumbs>
              <Link to="/admin">Dashboard</Link>
              <Link to="/admin/homepage">Homepage</Link>
              <span> {title}</span>
            </Breadcrumbs>
            <h1>Edit {title}</h1>
          </div>
          <section className="page-detail-container">
            <div className="py30 px20">
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12}>
                  <div
                    className={`input mb0 pr5 spacer ${
                      loading ? "iconed" : ""
                    }`}
                  >
                    <label> Title</label>
                    <input
                      type="text"
                      className="input-form-control"
                      name="title"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      placeholder={"Meeting Title"}
                    />
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12}>
                  <div
                    className={`input mb0 spacer ${loading ? "iconed " : ""}`}
                  >
                    <label>Section Title</label>
                    <input
                      type="text"
                      className="input-form-control"
                      name="section_title"
                      value={section_title}
                      onChange={(e) => setSectionTitle(e.target.value)}
                      placeholder={"section_title "}
                    />
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12}>
                  <div
                    className={`input mb0 spacer ${loading ? "iconed " : ""}`}
                  >
                    <label>Section Sub Title</label>
                    <input
                      type="text"
                      className="input-form-control"
                      name="section_sub_title"
                      value={section_sub_title}
                      onChange={(e) => setSectionSubTitle(e.target.value)}
                      placeholder={"section sub title "}
                    />
                  </div>
                </Grid>
              </Grid>
              <div className="banner-section">
                {preview_image && (
                  <div className="image_preview">
                    <img className="" src={preview_image} alt="preview Image" />
                  </div>
                )}
                <div
                  className={
                    loading
                      ? " input mb0 iconed mt20 pt10"
                      : " input mb0 mt20 pt10"
                  }
                >
                  <label>Attach homepage's Banner</label>
                  <input
                    type="file"
                    className="form-control"
                    name="file"
                    onChange={handleFileChange}
                    placeholder={"picture"}
                  />
                </div>
              </div>

              <div className="mb10">
                <DefaultEditor
                  className="form-control"
                  placeholder="Content"
                  value={section_content}
                  onChange={onHtmlChange}
                />
              </div>
              <Button
                type="submit"
                size="large"
                variant="contained"
                disabled={loading}
                onClick={handleSubmit}
              >
                {loading ? "Working..." : " Edit Section"}
              </Button>
            </div>
          </section>
        </Card>
      )}

      {loading_homepage && (
        <Card>
          <LinearProgress />
          <div>Loading....</div>
        </Card>
      )}
      {modal.onopen && <CustomModal data={modal} />}
    </div>
  );
};

export default Edit;
