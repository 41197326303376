import React from "react";
import Header from "../Header/Header";
import PageIntro from "./PageIntro/PageIntro";
import { Link } from "react-router-dom";
import Footer from "../Footer/Footer";
import SeoModule from "../../services/SeoModule";
import Calendar from "../templates/Calendar";
const EventsCalendar = (props: any) => {
  return (
    <React.Fragment>
      <SeoModule
        title={`Dianaokwu Ukaoye - Calendar `}
        description={"Events Calendar"}
        name="Reva"
        page_url={process.env.REACT_APP_SERVER_DOMAIN}
        page_image={process.env.REACT_APP_SERVER_DOMAIN + "images/logo.png"}
        type="ukaoye"
      />
      <section className="page-main">
        <div className="home-overlay"></div>
        <Header />
      </section>

      <section className="container pxy20 ">
        <div className="calendar-dark-bg">
          <Calendar />
        </div>
      </section>
      <Footer />
    </React.Fragment>
  );
};

export default EventsCalendar;
