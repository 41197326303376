import React from "react";
import { Link, NavLink } from "react-router-dom";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import MailOutline from "@mui/icons-material/MailOutline";
import PlaceHolder from "../../templates/PlaceHolder";
import HttpService from "../../../services/HttpService";
import DatePipe from "../../../pipes/DatePipe";
import { Breadcrumbs } from "@mui/material";
import Currency from "../../../pipes/Currency";
import { DoneAll, Pending } from "@mui/icons-material";
import Loading from "../../templates/Loading";

const All = (props: any) => {
  console.log(props);
  const { item, loaded, loading } = props;
  return (
    <React.Fragment>
      <section>
        {loaded && (
          <List
            sx={{
              p: "0",
              m: "0",
              // selected and (selected + hover) states
              "&& .Mui-selected, && .Mui-selected:hover": {
                bgcolor: "#000000",
                "&, & .MuiListItemIcon-root": {
                  color: "#ffffff",
                },
              },
              // hover states
              "& .MuiListItemButton-root:hover": {
                bgcolor: "#dedede",
                "&, & .MuiListItemIcon-root": {
                  color: "#000000",
                },
              },
            }}
          >
            {item.map((item: any, index: number) => (
              <ListItem
                disablePadding
                button
                key={item.id}
                divider={true}
                component={NavLink}
                to={`/account/clearance/p/${item.id}`}
                secondaryAction={<Currency value={item.amount} />}
              >
                <ListItemButton>
                  <ListItemIcon>
                    {item.is_paid ? <DoneAll /> : <Pending />}
                  </ListItemIcon>
                  <ListItemText
                    secondary={
                      <span className="date-span">
                        <DatePipe
                          value={new Date(item.created_at).getTime() * 1000}
                        />
                      </span>
                    }
                  >
                    <Typography component={"h2"}>{item.title}</Typography>{" "}
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        )}
        {loaded && item.length === 0 && (
          <div className="result-error">
            <span className="status-icon">
              <i className="fas fa-exclamation-triangle"></i>
            </span>
            <h3> Not found!</h3>
          </div>
        )}
      </section>

      {loading && <Loading />}
    </React.Fragment>
  );
};

export default All;
