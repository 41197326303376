import React from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import AuthService from "../../services/AuthService";
import "./Header.css";
import { Collapse, Tooltip } from "@mui/material";
import DesktopNav from "./DesktopNav";
import MobileNav from "./MobileNav";
import useFetchEventCategories from "../../hooks/useFetchEventCategories";

const Header = (props: any) => {
  const { base_page } = props;

  const {
    event_categories,
    loading_event_categories,
    loaded_event_categories,
  } = useFetchEventCategories();

  const [isTogged, setIsTogged] = React.useState(false);
  const page = useLocation().pathname;
  const logx = AuthService.isLogged();
  const usr = AuthService.getCurrentUser();
  const [isLogged, setIsLogged] = React.useState(logx);
  const navigate = useNavigate();

  const doLogout = () => {
    AuthService.logout();
    setTimeout(() => {
      console.log("Session Cleared...");
      setIsLogged(false);
      return navigate("/");
    }, 300);
  };

  const closeNav = () => {
    setNavData({ ...nav_data, onopen: false });
  };
  const [nav_data, setNavData] = React.useState<any>({
    ononpen: false,
    onclose: closeNav,
    isLogged: isLogged,
  });
  const toggleNav = () => {
    setNavData({
      ...nav_data,
      isLogged: isLogged,
      onopen: true,
      onclose: closeNav,
    });
    setIsTogged((cur) => !cur);
  };

  const doBoth = () => {
    doLogout();
    toggleNav();
  };

  const showMobileNav: boolean = isTogged ? true : false;

  return (
    <React.Fragment>
      <header
        className={`main-header ${base_page === "home" ? "home-header" : ""}`}
      >
        <div className="header-container">
          <div className="logo">
            <Link to="/">
              <img src="/images/logo2.png" alt="Logo" />
            </Link>
          </div>
          <span className="spacer"></span>

          <DesktopNav
            doLogout={doLogout}
            usr={usr}
            isLogged={isLogged}
            event_categories={event_categories}
            loading_event_categories={loading_event_categories}
            loaded_event_categories={loaded_event_categories}
          />

          <span className="mobile-nav">
            <button className="mnav" onClick={() => setIsTogged(!isTogged)}>
              <i className={`fas ${isTogged ? "fa-close" : "fa-bars"}`}></i>
            </button>
          </span>
        </div>
        <div className="mobile-header-bar">
          <Collapse in={isTogged}>
            <MobileNav
              doLogout={doLogout}
              isLogged={isLogged}
              setIsTogged={setIsTogged}
              isTogged={isTogged}
              event_categories={event_categories}
              loading_event_categories={loading_event_categories}
              loaded_event_categories={loaded_event_categories}
            />
          </Collapse>
        </div>
      </header>
    </React.Fragment>
  );
};

export default Header;
