import React from "react";
import { Routes, Route } from "react-router-dom";

import Edit from "./Edit";
import List from "./List";

const Homepage = (props: any) => {
  return (
    <React.Fragment>
      <Routes>
        <Route path="" element={<List />} />
        <Route path="/" element={<List />} />
        <Route path="/e/:slug" element={<Edit />} />
      </Routes>
    </React.Fragment>
  );
};

export default Homepage;
