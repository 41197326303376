import React from "react";
import HttpService from "../services/HttpService";

const useFetchHomepage = () => {
  const [homepage, setHome] = React.useState<any[]>([]);
  const [loading_homepage, setLoading] = React.useState(false);
  const [loaded_homepage, setLoaded] = React.useState(false);

  React.useEffect(() => {
    fetch_case();
  }, []);

  const fetch_case = () => {
    setLoading(true);
    setLoaded(false);
    HttpService.postHeader("get_homepage", {})
      .then(
        (res) => {
          if (res.status === 1) {
            setHome(res.data);
          }
        },
        (err) => {
          console.log(err);
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      });
  };

  return {
    homepage,
    loading_homepage,
    loaded_homepage,
  };
};

export default useFetchHomepage;
