import React from "react";
import { Link } from "react-router-dom";
import PlaceHolder from "../../templates/PlaceHolder";
import HttpService from "../../../services/HttpService";
import EventsListTemplate from "../../templates/EventsListTemplate";

const HomeEvents = (props: any) => {
  const [offset, setOffset] = React.useState<number>(0);
  const [events, setEvent] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [page_data, setPageDate] = React.useState<any>({});

  React.useEffect(() => {
    listEvent(offset);
  }, []);

  const listEvent = (offset: number) => {
    setLoading(true);
    setLoaded(false);
    HttpService.postHeader("all_events", { offset: offset, limit: 6 })
      .then(
        (result) => {
          console.log(result);
          if (result.status === 1) {
            let newRes = [...events, ...result.data];
            setEvent(result.data);
          }
        },
        (error) => {}
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //fetch
  }; //doAjax

  return (
    <React.Fragment>
      <div className="thinking-container">
        <div className="container">
          <div className="section-title">
            <h2>Recent Village Events</h2>
          </div>
          {loaded && (
            <EventsListTemplate
              loading={loading}
              loaded={loaded}
              events={events}
              max_grid={4}
            />
          )}
          {loading && <PlaceHolder type="articles" />}
          <div className="flex justify-content-center align-items-center py20">
            <Link to="/events" className="more">
              MORE EVENTS
            </Link>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default HomeEvents;
