import React from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import DatePipe from "../../pipes/DatePipe";
import * as processHtml from "../../services/processHtml";
import Tooltip from "@mui/material/Tooltip";
import HttpService from "../../services/HttpService";
import useCheckLogged from "../../hooks/useCheckLogged";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import Slide from "@mui/material/Slide";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import AuthService from "../../services/AuthService";
import PromptLogin from "./PromptLogin";
import { Favorite, FavoriteBorderOutlined } from "@mui/icons-material";
import { IconButton } from "@mui/material";

const TransitionUp = React.forwardRef(function Transition(
  props: any,
  ref: any
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const LikeTemplate = (props: any) => {
  const { item, item_label } = props;
  let navigate = useNavigate();
  const [loaded, setLoaded] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [likes, setLikes] = React.useState(item.like_num);
  const [like_state, setLikeState] = React.useState(item.is_liked);
  const [dislikes, setDislikes] = React.useState(item.dislike_num);
  const [info, setInfo] = React.useState({});

  const [is_logged, setIslogged] = React.useState(false);

  const usr = AuthService.getCurrentUser();
  React.useEffect(() => {
    if (usr) {
      setIslogged(true);
    }
  }, [usr]);

  const sendLike = (act: string) => {
    const load = {
      act: act,
      label: item_label,
      item_id: item.id,
      mode: "like_item",
    };
    console.log(load);
    console.log(usr);
    if (!is_logged) {
      return;
    }
    setLoading(true);
    setLoaded(false);
    // return;
    HttpService.postHeader("like_item", load)
      .then(
        (result) => {
          console.log(result);
          if (result.status === 1) {
            setLike(result);
          } else {
            alert(result.message);
          }
        },
        (error) => {
          console.log(error.message);
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //fetch
  }; //doAjax
  const setLike = (data: any) => {
    console.log(data);
    if (data["act"] === "like") {
      setLikes(data.likes);
      if (data.did === "liked") {
        setLikeState({ ...like_state, is_liked: true });
      } else if (data.did === "unliked") {
        setLikeState({ ...like_state, is_liked: false });
      }
    } else if (data["act"] === "dislike") {
      setDislikes(data.dislikes);
    }
  };

  const doSL = (mode: string) => {
    sendLike(mode);
  };

  const closeConfirm = () => {
    setConfirm({ ...confirm, onopen: false });
  };
  const [confirm, setConfirm] = React.useState<any>({
    onopen: false,
    onclose: closeConfirm,
  });

  const doSLx = (mode: string) => {
    doSL(mode);
    return;
    AuthService.checkSession().then(
      (res) => {
        console.log(res);
        if (!res.status || res.status === 0) {
          AuthService.logout();
          setConfirm({ ...confirm, onopen: true, onclose: closeConfirm });
          return;
        }
      },
      (error) => {
        console.log(error.message);
        AuthService.logout();
        return navigate("/login");
      }
    );
    console.log("Comm togged...");
  };
  return (
    <React.Fragment>
      <div className="react-span">
        <span className=" flex align-items-center">
          <Tooltip
            arrow
            placement="top"
            title={like_state?.is_liked ? "Remove your Like" : "Like this"}
          >
            <IconButton disabled={loading} onClick={() => doSLx("like")}>
              {like_state?.is_liked ? (
                <Favorite sx={{ color: "rgb(239 3 121 / 100%)" }} />
              ) : (
                <FavoriteBorderOutlined />
              )}
            </IconButton>
          </Tooltip>

          <span className="count-span">{likes}</span>
        </span>
        {/*  <span className="pr5">
          <button
            disabled={loading}
            className={
              is_logged
                ? "primary react-btn dislike"
                : "primary react-btn dislike unlogged"
            }
            onClick={() => doSLx("dislike")}
          >
            <Tooltip title={`Dislike ${item_label}`}>
              <i className="fas fa-thumbs-down"></i>
            </Tooltip>
          </button>
          <span className="count-label">{dislikes}</span>
        </span>
        */}
        <span className="pr5"></span>
      </div>

      <Dialog
        open={confirm.onopen}
        aria-labelledby={"Me"}
        id={"md-"}
        TransitionComponent={TransitionUp}
      >
        <DialogContent sx={{ p: "20px", m: "0" }}>
          <section className="modal-width">
            <h2>You are not logged in.</h2>
            <p>Please login to like {item_label}</p>
            <div className="flex flex-row align-items-center">
              <span className="spacer">
                <PromptLogin button_text="Click to Login" />
              </span>
              <span>
                <Button color="warning" size="small" onClick={closeConfirm}>
                  Not Now
                </Button>
              </span>
            </div>
          </section>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default React.memo(LikeTemplate);
