import React from "react";
import { calendarData } from "../../data/calendar-data";
import FetchDate from "../../services/FetchDate";
import DateModal from "./DateModal";
import dateProcess from "../../services/dateProcess";

import SearchDateModal from "./SearchModal";
import IconButton from "@mui/material/IconButton";
import ArrowForwardIos from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNew from "@mui/icons-material/ArrowBackIosNew";
import Tooltip from "@mui/material/Tooltip";
import CalendarActions from "./CalendarActions";
import "../calendar.css";
import { Divider } from "@mui/material";

const Calendar = () => {
  const [calendar_data, setCalendar] = React.useState<any>({
    calendar_days: [],
    week_days: [],
    cur_date: "",
    cur_lang: "",
    last_month_last_day: "",
    next_month_first_day: "",
  });
  React.useEffect(() => {
    setCalendar(FetchDate(false, "igbo"));
    //console.log("Window:: ", Window);
    //(window.adsbygoogle:Window = window.adsbygoogle || []).push({});
  }, []);

  //console.log("cur_date:: ", calendar_data.cur_date);

  const jumpDate = React.useCallback(
    (dtx: any) => {
      //console.log(dtx, "::");
      let dt_arr = dateProcess(dtx, calendar_data.cur_lang);
      setCalendar(FetchDate(dtx, calendar_data.cur_lang));
      launchDate(dt_arr);
      //console.log(dt_arr, "::dt_arr");
    },
    [calendar_data]
  );

  const onModalClose = () => {
    setModal({ ...modal, onopen: false, loaded: false });
  };
  const onSearchClose = () => {
    setSearch({ ...search, onopen: false, loaded: false });
  };

  const [modal, setModal] = React.useState<any>({
    onopen: false,
    onclose: onModalClose,
    loaded: false,
  });
  const [search, setSearch] = React.useState<any>({
    onopen: false,
    onclose: onSearchClose,
    loaded: false,
  });

  const launchDate = (item: any) => {
    console.log(item, "::lnch");
    setModal({
      ...modal,
      onopen: true,
      loaded: true,
      cur_lang: calendar_data.cur_lang,
      ...item,
    });
  };

  const switchLang = (lang: string) => {
    setCalendar(FetchDate(calendar_data.cur_date, lang));
  };
  const goNext = (idate: string) => {
    console.log(idate, "::");
    setCalendar(FetchDate(idate, calendar_data.cur_lang));
  };

  const launchSearch = () => {
    setSearch({ ...search, onopen: true, loaded: true });
  };

  return (
    <React.Fragment>
      <section className="calendar-page">
        <div className="curver">
          <div
            className="
          summary-cell flex 
          align-items-center "
          >
            <div className="spacer flex flex-row align-items-center pl10">
              <div>
                <span className="pr10">
                  <Tooltip title="Previous Month">
                    <a
                      className="search-link"
                      onClick={() => goNext(calendar_data.last_month_last_day)}
                    >
                      <i className="fas fa-chevron-left"></i>
                    </a>
                  </Tooltip>
                </span>
                <span className="spacer text-center px5">
                  {calendar_data.cur_month_label},{" "}
                  {calendar_data.cur_year_label}
                </span>
                <span className="pl10">
                  <Tooltip title="Next Month">
                    <a
                      className="search-link"
                      onClick={() => goNext(calendar_data.next_month_first_day)}
                    >
                      <i className="fas fa-chevron-right"></i>
                    </a>
                  </Tooltip>
                </span>
              </div>
            </div>
            <Divider orientation="vertical" />
            <div className="text-right">
              <CalendarActions
                doSwitch={switchLang}
                doSearch={launchSearch}
                cur_lang={calendar_data.cur_lang}
              />
            </div>
          </div>
          <div className="cells-container">
            <div className="week-bar">
              {calendar_data.week_days.map((item: any) => (
                <div
                  key={item.day}
                  className="
          week-cell flex 
          align-items-center 
          justify-content-center"
                >
                  <Tooltip title={item.day}>
                    <>
                      <span className="sm">{item.short}</span>
                      {/*   <span className="sm-hide-inline-block">{item.day}</span> */}
                    </>
                  </Tooltip>
                </div>
              ))}
            </div>
            {calendar_data.calendar_days.map((item: any, index: number) => (
              <div className="week-bar" key={item + index}>
                {item.map((itm: any, indx: number) => (
                  <div
                    key={indx}
                    className={
                      itm.todays_class === "today_active"
                        ? "day-cell today_active"
                        : itm.disabled === "disabled"
                        ? "day-cell disabled"
                        : "day-cell"
                    }
                    onClick={() => launchDate(itm)}
                  >
                    <span className="day-span">{itm.todays_count}</span>
                    <span className="trad-span">{itm.trad_day}</span>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </section>
      {search.loaded && (
        <SearchDateModal
          onclose={search.onclose}
          onopen={search.onopen}
          fetch={jumpDate}
        />
      )}
      {modal.onopen && <DateModal data={modal} />}
    </React.Fragment>
  );
};

export default Calendar;
