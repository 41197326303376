import React from "react";
import { Link } from "react-router-dom";
import HttpService from "../../../services/HttpService";
import { Grid } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBarsProgress } from "@fortawesome/free-solid-svg-icons";
import { faUnity } from "@fortawesome/free-brands-svg-icons";
const HomeFeatures = () => {
  const features = [
    { path: "/free", text: "Peace", icon: "free.svg" },
    {
      path: "/free",
      text: "Unity",
      icon: "free.svg",
    },
    {
      path: "/free",
      text: "Progress",
      icon: "free.svg",
    },
  ];

  return (
    <React.Fragment>
      <section className="home-features">
        <div className="container">
          {/*  <div className="section-title">
            <h2>3 Pillars of Dianaokwu Village</h2>
          </div >*/}

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} sx={{ p: "10px" }}>
              <div className="feature-container">
                <div className="feature-icon">
                  {/* <img
                      src={`/images/features/${item.icon}`}
                      alt={item.text}
                    /> */}
                  <i className="fa-solid fas fa-peace"></i>
                </div>
                <div className="feature-text">PEACE</div>
              </div>
            </Grid>

            <Grid item xs={12} sm={6} md={4} sx={{ p: "10px" }}>
              <div className="feature-container">
                <div className="feature-icon">
                  {/* <img
                      src={`/images/features/${item.icon}`}
                      alt={item.text}
                    /><i className="fa-brands fas fa-unity"></i> */}
                  <FontAwesomeIcon icon={faUnity} />
                </div>
                <div className="feature-text">UNITY</div>
              </div>
            </Grid>

            <Grid item xs={12} sm={6} md={4} sx={{ p: "10px" }}>
              <div className="feature-container">
                <div className="feature-icon">
                  {/* <img
                      src={`/images/features/${item.icon}`}
                      alt={item.text}
                    /> */}
                  <FontAwesomeIcon icon={faBarsProgress} />
                </div>
                <div className="feature-text">PROGRESS</div>
              </div>
            </Grid>
          </Grid>
        </div>
      </section>
    </React.Fragment>
  );
};

export default React.memo(HomeFeatures);
