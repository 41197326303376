import React from "react";
import { Link } from "react-router-dom";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import Menu from "@mui/material/Menu";
import Language from "@mui/icons-material/Language";
import { styled, useTheme } from "@mui/material/styles";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import MenuItem from "@mui/material/MenuItem";
import { CopyrightSharp, Search, Translate } from "@mui/icons-material";
import List from "@mui/material/List";
import Box from "@mui/material/Box";

const CalendarActions = (props: any) => {
  const { doSearch, doSwitch, children, nav_menu, cur_lang } = props;
  const pages: any[] = [
    { path: "english", component: "English", icon: <Language /> },
    { path: "hausa", component: "Hausa", icon: <Language /> },
    { path: "yoruba", component: "Yoruba", icon: <Language /> },
    { path: "igbo", component: "Igbo", icon: <Language /> },
  ];
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event: any) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  return (
    <React.Fragment>
      {doSearch && (
        <span className="pl10">
          <Tooltip title="Search Date">
            <a className="search-link" onClick={doSearch}>
              <i className="fas fa-search"></i>
              <span className="pl5 sm-hide-inline-block">Search</span>
            </a>
          </Tooltip>
        </span>
      )}
    </React.Fragment>
  );
};

export default CalendarActions;
