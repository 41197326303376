import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Breadcrumbs, Card } from "@mui/material";
import Button from "@mui/material/Button";
import { DefaultEditor } from "react-simple-wysiwyg";
import HttpService from "../../../services/HttpService";
import CustomModal from "../../templates/CustomModal";
import useFetchPage from "../../../hooks/useFetchPage";

const EditPage = (props: any) => {
  const { slug } = props;
  let navigate = useNavigate();
  const params = useParams();
  console.log(params);
  const isParam = params.pageId ? true : false;
  let [loading, setLoading] = React.useState<any>(false);
  let [loaded, setLoaded] = React.useState<any>(false);
  const { page, loading_page, loaded_page } = useFetchPage({
    slug: slug,
  });
  let [desc, setDesc] = React.useState("");
  let [title, setTitle] = React.useState("");
  const closeModal = () => {
    setModal({ ...modal, onopen: false });
  };
  const [modal, setModal] = React.useState<any>({
    onopen: false,
    onclose: closeModal,
    title: "Edit page",
  });

  React.useEffect(() => {
    setTitle(page.title);
    setDesc(page.description);
  }, [page]);
  const onHtmlChange = (e: any) => {
    setDesc(e.target.value);
  };

  const handleSubmit = () => {
    setLoading(true);
    setLoaded(false);
    console.log("SUBMITTING");
    HttpService.post(
      {
        title: title,
        description: desc,
        id: page?.id,
      },
      "edit_page"
    )
      .then((resp) => {
        console.log(resp);
        if (resp.status === 1) {
          setModal({
            ...modal,
            onopen: true,
            severity: "success",
            message: resp.message,
          });
        } else {
          setModal({
            ...modal,
            onopen: true,
            severity: "error",
            message: resp.message,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setModal({
          ...modal,
          onopen: true,
          severity: "error",
          message: err.message,
        });
      })
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      });
  };
  return (
    <React.Fragment>
      <div className="pxy20">
        <div className={loading_page ? " input iconed " : " input "}>
          <label>Page title</label>
          <input
            type="text"
            className="input-form-control"
            name="name"
            disabled={loading_page || loading}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder={"page Title"}
          />
        </div>

        <div className="mb10">
          <DefaultEditor
            className="input-form-control"
            value={desc}
            placeholder="Page Detail"
            disabled={loading_page || loading}
            onChange={onHtmlChange}
          />
        </div>
        <Button
          type="submit"
          size="large"
          variant="contained"
          disabled={loading || loading_page}
          onClick={handleSubmit}
        >
          {loading ? "Working..." : " Edit page "}
        </Button>
      </div>

      {modal.onopen && <CustomModal data={modal} />}
    </React.Fragment>
  );
};

export default EditPage;
