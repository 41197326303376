import React from "react";
import { Link, NavLink, useParams } from "react-router-dom";
import HttpService from "../../../services/HttpService";
import * as processHtml from "../../../services/processHtml";
import EventsListTemplate from "../../templates/EventsListTemplate";
import PlaceHolder from "../../templates/PlaceHolder";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Header from "../../Header/Header";
import PageIntro from "../PageIntro/PageIntro";
import SeoModule from "../../../services/SeoModule";
import { Button, LinearProgress } from "@mui/material";
import EventsLayout from "./EventsLayout";
const EventCategory = (props: any) => {
  const params = useParams();
  const [loading_events, setLoading] = React.useState(true);
  const [offset, setOffset] = React.useState<number>(0);
  const [events, setEvent] = React.useState<any>({ data: [] });
  const [list, setList] = React.useState<any[]>([]);
  const [loaded_events, setLoaded] = React.useState<boolean>(false);
  const [page_data, setPageDate] = React.useState<any>({
    page_title: "",
    page_slug: "",
  });
  const [isParam, setParam] = React.useState(false);
  const [catUrl, setCatUrl] = React.useState<any>(null);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    const isParam = params.catUrl ? true : false;
    setParam(isParam);
    if (isParam) {
      setCatUrl(params.catUrl);
      listEvent(params.catUrl, 0);
    }
  }, [catUrl, params]);

  const listEvent = (cat_url: any, offset: number) => {
    setLoading(true);
    setLoaded(false);
    HttpService.postHeader("events_by_category", {
      offset: offset,
      limit: 120,
      cat_url: cat_url,
    })
      .then(
        (result) => {
          setLoading(false);
          console.log(result);
          if (result.status === 1) {
            setPageDate({
              ...page_data,
              page_title: result.title,
              page_slug: result.slug,
              page_summary: result.title,
            });
            setEvent(result);
          } else {
          }
        },
        (error) => {}
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //fetch
  }; //doAjax

  const loadMore = () => {
    const newOffset = offset + 20;
    setOffset(newOffset);
    listEvent(catUrl, newOffset);
  };

  return (
    <React.Fragment>
      <EventsLayout page_data={page_data}>
        {loaded_events && (
          <EventsListTemplate
            loaded={loaded_events}
            loading={loading_events}
            events={events.data}
            max_grid={4}
          />
        )}
        {loading_events && <PlaceHolder type="articles" max_grid={4} />}
        {loaded_events && events?.data?.length > 0 && (
          <div className="flex justify-content-center align-items-center py20">
            <button
              disabled={loading_events}
              className="more"
              onClick={loadMore}
            >
              {loading_events ? "Loading..." : "Load More"}
            </button>
          </div>
        )}
        {loaded_events && events?.data?.length === 0 && (
          <div className="empty-list">
            <span className="empty-icon">
              <i className="fas fa-exclamation-triangle"></i>{" "}
            </span>
            <span className="empty-text"> No Events Found!</span>
          </div>
        )}
      </EventsLayout>
    </React.Fragment>
  );
};

export default EventCategory;
