import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";

const TransitionUp = React.forwardRef(function Transition(
  props: any,
  ref: any
) {
  return <Slide direction="up" ref={ref} {...props} />;
});



const DateModal = (props: any) => {
  const modal = props.data;
  const handleClose = () => {
    modal.onclose();
  };
  const tmx = new Date().getTime();

  return (
    <>
      <Dialog
        open={modal.onopen}
        aria-labelledby={"Me"}
        id={"md-" + tmx}
        TransitionComponent={TransitionUp}
      >
        {/*<div className="flex flex-row align-items-center px10 py5">
           <DialogTitle id={"label100"} sx={{ p: "14px" }}>
            <i className="fas fa-calendar"></i>{" "}
            {modal.todays_date || "Date Info"}
  </DialogTitle>

          <span className="spacer"></span>
          <IconButton onClick={handleClose} color="warning">
            <Close />
          </IconButton>
        </div>*/}

        <section className="modal-width">
          {/* 
            {JSON.stringify(modal)} */}

          <div className="date-card">
            <div className="date-point">
              <span className="desc-span">{modal.week_object.label}</span>
              <span className="digit-span">{modal.todays_day_num}</span>
              <div className="desc-span">
                <span>{modal.week_object.day}</span>
              </div>
            </div>

            <div className="date-point">
              <span className="desc-span">{modal.month_object.label}</span>
              <span className="digit-span">
                {modal.todays_month_offset + 1}
              </span>
              <span className="desc-span">{modal.month_object.month}</span>
            </div>

            <div className="date-point">
              <span className="desc-span">Year</span>
              <span className="digit-span">{modal.todays_year}</span>{" "}
              <span className="desc-span">Anno Domini</span>
            </div>
          </div>
          <div className="py5"></div>
          {modal.cur_lang === "igbo" && (
            <>
              <div className="exp-div">
                Ụbọchi taa bụ {modal.week_object.day}
                <span className="trad_illus">
                  {modal.trad_day ? `  ${modal.trad_day}` : ""}
                </span>
                . Mkpụrụ ụbọchi {modal.day_object.day} nke ọnwa{" "}
                {modal.month_object.month} na afọ {modal.todays_year}
              </div>
            </>
          )}
          <div className="close-button-cover">
            <button className="close-modal" onClick={() => modal.onclose()}>
              <span className="spacer">CLOSE</span>
              <span className="fa fa-close px10"></span>
            </button>
          </div>
        </section>

        <DialogContent sx={{ p: "0", m: "0" }}>
          {/*  <section className="modal-width px20">
            {!loading && (
              <h3 className="h3-underline">
                {modal.month_object.month} {modal.todays_day_num} in history
              </h3>
            )}
            {loading && (
              <Box sx={{ width: "100%" }}>
                <LinearProgress />
                <div className="py5">Fetching historic event of the day...</div>
              </Box>
            )}
            {!loading && history.length < 1 && (
              <div className="pb10">No recorded historic events</div>
            )}
            <HistoryCard list={history} loading={loading} />
          </section> */}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default React.memo(DateModal);
