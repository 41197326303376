import React from "react";
import { Link } from "react-router-dom";
import PlaceHolder from "../../templates/PlaceHolder";
import Button from "@mui/material/Button";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import HttpService from "../../../services/HttpService";
import * as processHtml from "../../../services/processHtml";
import Header from "../../Header/Header";
import PageIntro from "../PageIntro/PageIntro";
import Footer from "../../Footer/Footer";
import { Card } from "@mui/material";
import EventsLayout from "./MembersLayout";
import MemberListTemplate from "../../templates/MemberListTemplate";
import MembersLayout from "./MembersLayout";

const MembersList = (props: any) => {
  const [offset, setOffset] = React.useState<number>(0);
  const [members, setMembers] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [page_data, setPageDate] = React.useState<any>({});

  React.useEffect(() => {
    setPageDate({
      ...page_data,
      page_slug: "",
      page_title: "All Members",
      page_summary: "All Dianaokwu  Members",
    });
    listEvent(offset);
  }, []);

  const listEvent = (offset: number) => {
    setLoading(true);
    setLoaded(false);
    HttpService.postHeader("all_members", {
      offset: offset,
      limit: 24,
      mode: "all",
    })
      .then(
        (result) => {
          console.log(result);
          if (result.status === 1) {
            let newRes = [...members, ...result.data];
            setMembers(result.data);
          }
        },
        (error) => {}
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //fetch
  }; //doAjax

  const loadMore = () => {
    const newOffset = offset + 20;
    setOffset(newOffset);
    listEvent(newOffset);
  };

  return (
    <React.Fragment>
      <MembersLayout page_data={page_data}>
        {loaded && (
          <MemberListTemplate
            loading={loading}
            loaded={loaded}
            members={members}
            max_grid={3}
          />
        )}
        {loading && <PlaceHolder type="articles" />}
        <div className="flex justify-content-center align-items-center py20">
          <button disabled={loading} className="more" onClick={loadMore}>
            {loading ? "Loading..." : "Load More"}
          </button>
        </div>
      </MembersLayout>
    </React.Fragment>
  );
};

export default MembersList;
