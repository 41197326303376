import React from "react";
import { useParams } from "react-router-dom";
import GalleryLayout from "./GalleryLayout";
import PlaceHolder from "../../templates/PlaceHolder";
import HttpService from "../../../services/HttpService";

const GalleryCategory = (props: any) => {
  const params = useParams();
  const [loading_gallery, setLoading] = React.useState(true);
  const [offset, setOffset] = React.useState<number>(0);
  const [gallery, setGallery] = React.useState<any>({ data: [] });
  const [list, setList] = React.useState<any[]>([]);
  const [loaded_gallery, setLoaded] = React.useState<boolean>(false);
  const [page_data, setPageDate] = React.useState<any>({
    page_title: "",
    page_slug: "",
  });
  const [isParam, setParam] = React.useState(false);
  const [catUrl, setCatUrl] = React.useState<any>(null);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    const isParam = params.catUrl ? true : false;
    setParam(isParam);
    if (isParam) {
      setCatUrl(params.catUrl);
      listGallery(params.catUrl, 0);
    }
  }, [catUrl, params]);

  const listGallery = (cat_url: any, offset: number) => {
    setLoading(true);
    setLoaded(false);
    HttpService.post(
      {
        offset: offset,
        limit: 120,
        cat_url: cat_url,
      },
      "gallery_by_category"
    )
      .then(
        (result) => {
          setLoading(false);
          console.log(result);
          if (result.status === 1) {
            setPageDate({
              ...page_data,
              page_title: result.title,
              page_slug: result.slug,
              page_summary: result.title,
            });
            setGallery(result);
          } else {
          }
        },
        (error) => {}
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //fetch
  }; //doAjax

  const loadMore = () => {
    const newOffset = offset + 20;
    setOffset(newOffset);
    listGallery(catUrl, newOffset);
  };

  return (
    <React.Fragment>
      <GalleryLayout page_data={page_data}>
        {loaded_gallery && <div>LIST CATS</div>}
        {loading_gallery && <PlaceHolder type="articles" />}
        {loaded_gallery && gallery?.data?.length > 0 && (
          <div className="flex justify-content-center align-items-center py20">
            <button
              disabled={loading_gallery}
              className="more"
              onClick={loadMore}
            >
              {loading_gallery ? "Loading..." : "Load More"}
            </button>
          </div>
        )}
        {loaded_gallery && gallery?.data?.length === 0 && (
          <div className="empty-list">
            <span className="empty-icon">
              <i className="fas fa-exclamation-triangle"></i>{" "}
            </span>
            <span className="empty-text"> No Gallery Item Found!</span>
          </div>
        )}
      </GalleryLayout>
    </React.Fragment>
  );
};
export default GalleryCategory;
