import React from "react";
import { NavLink } from "react-router-dom";
import MuiDrawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Tooltip from "@mui/material/Tooltip";
import { styled, Theme, CSSObject } from "@mui/material/styles";
import {
  Settings,
  CardMembershipOutlined,
  DashboardOutlined,
  Event,
  MeetingRoomOutlined,
  MessageSharp,
  PaymentOutlined,
  PaymentsOutlined,
  StopCircleOutlined,
  VerifiedUserTwoTone,
  CabinOutlined,
  FolderCopyOutlined,
  HomeOutlined,
  InfoOutlined,
  MultipleStopOutlined,
  Leaderboard,
} from "@mui/icons-material";

const drawerWidth = 320;
const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: "0",
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  backgroundColor: "#000",
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const AdminSidePanel = (props: any) => {
  let { onopen, onclose, toggleDrawer, DrawerHeader, doLogout } = props;

  const pages: any[] = [
    {
      path: "dashboard",
      title: "Dashboard ",
      navItem: true,
      icon: <DashboardOutlined />,
      data: { mustAuth: true, isAdmin: false, showSideNav: false },
    },
    {
      path: "homepage",
      title: "Home Page",
      icon: <HomeOutlined />,
      navItem: true,
      data: { mustAuth: true, isAdmin: false, showSideNav: false },
    },
    {
      path: "about",
      title: "About Dianokwu",
      icon: <InfoOutlined />,
      navItem: true,
      data: { mustAuth: true, isAdmin: false, showSideNav: false },
    },
    {
      path: "members",
      title: "Members",
      icon: <CardMembershipOutlined />,
      navItem: true,
      data: { mustAuth: true, isAdmin: false, showSideNav: false },
    },
    {
      path: "meetings",
      title: "Meetings",
      icon: <MeetingRoomOutlined />,
      navItem: true,
      data: { mustAuth: true, isAdmin: false, showSideNav: false },
    },
    {
      path: "events",
      title: "Events",
      icon: <Event />,
      navItem: true,
      data: { mustAuth: true, isAdmin: false, showSideNav: false },
    },

    {
      path: "leaders",
      title: "Leaders",
      icon: <Leaderboard />,
      navItem: true,
      data: { mustAuth: true, isAdmin: false, showSideNav: false },
    },
    {
      path: "comittees",
      title: "comittees",
      icon: <MultipleStopOutlined />,
      navItem: true,
      data: { mustAuth: true, isAdmin: false, showSideNav: false },
    },
    {
      path: "levies",
      title: "Levies",
      icon: <PaymentOutlined />,
      navItem: true,
      data: { mustAuth: true, isAdmin: false, showSideNav: false },
    },
    {
      path: "penalties",
      title: "Penalties",
      icon: <StopCircleOutlined />,
      navItem: true,
      data: { mustAuth: true, isAdmin: false, showSideNav: false },
    },
    {
      path: "non-indigenes",
      title: "Non Indigenes",
      icon: <VerifiedUserTwoTone />,
      navItem: true,
      data: { mustAuth: true, isAdmin: false, showSideNav: false },
    },
    {
      path: "transactions",
      title: "Transactions",
      icon: <PaymentsOutlined />,
      navItem: true,
      data: { mustAuth: true, isAdmin: false, showSideNav: false },
    },

    {
      path: "messages",
      title: "Messages",
      icon: <MessageSharp />,
      navItem: true,
      data: { mustAuth: true, isAdmin: false, showSideNav: false },
    },
    {
      path: "cases",
      title: "Cases",
      icon: <FolderCopyOutlined />,
      navItem: true,
      data: { mustAuth: true, isAdmin: false, showSideNav: false },
    },
    {
      path: "records",
      title: "Records",
      icon: <CabinOutlined />,
      navItem: true,
      data: { mustAuth: true, isAdmin: false, showSideNav: false },
    },
    {
      path: "settings",
      title: "Settings",
      icon: <Settings />,
      navItem: true,
      data: { mustAuth: true, isAdmin: false, showSideNav: false },
    },
  ];

  const togg = () => {
    onclose();
  };
  return (
    <React.Fragment>
      <Drawer variant="permanent" open={onopen} onClose={onclose}>
        <DrawerHeader />
        <Divider />
        {pages.map(
          (item, index) =>
            item.path !== "" &&
            item.navItem &&
            item.path !== "*" && (
              <ListItem
                key={item.path}
                disablePadding
                className="drawer-link"
                component={NavLink}
                to={`/admin/${item.path}`}
              >
                <ListItemButton onClick={togg}>
                  <Tooltip
                    title={item.title}
                    placement="right-start"
                    arrow
                    followCursor
                  >
                    <ListItemIcon>{item.icon}</ListItemIcon>
                  </Tooltip>
                  <ListItemText
                    className="list-item-text-font"
                    primary={item.title}
                  />
                </ListItemButton>
              </ListItem>
            )
        )}
        <Divider />
        <ListItem disablePadding onClick={doLogout} className="drawer-link">
          <ListItemButton>
            <Tooltip
              title={"Log Out"}
              placement="right-start"
              arrow
              followCursor
            >
              <ListItemIcon>
                <i
                  className={`fas fa-sign-out-alt`}
                  style={{ color: "#ccc" }}
                ></i>
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary={"LOG OUT"} />
          </ListItemButton>
        </ListItem>
      </Drawer>
    </React.Fragment>
  );
};

export default AdminSidePanel;
