import {
  Avatar,
  Card,
  Grid,
  Icon,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import React from "react";
import useFetchHomepage from "../../../hooks/useFetchHomepage";
import { Edit } from "@mui/icons-material";

const Homepage = () => {
  const { homepage, loading_homepage, loaded_homepage } = useFetchHomepage();

  const redir = () => {};
  return (
    <React.Fragment>
      <section className="dashboard-pane">
        <Grid container spacing={2}>
          {homepage.map((item) => (
            <Grid item xs={12} sm={6} md={4} key={item.path}>
              <Card className="bga">
                <ListItem
                  secondaryAction={
                    <IconButton href={`/admin/homepage/e/${item.slug}`}>
                      <Edit />
                    </IconButton>
                  }
                >
                  <ListItemAvatar>
                    <Avatar src={item.section_photo} />
                  </ListItemAvatar>
                  <ListItemButton>
                    <ListItemText
                      primary={<div>{item.title}</div>}
                    ></ListItemText>
                  </ListItemButton>
                </ListItem>
              </Card>
            </Grid>
          ))}
        </Grid>
      </section>
    </React.Fragment>
  );
};

export default Homepage;
