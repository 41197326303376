import { Link, NavLink, useNavigate } from "react-router-dom";
import {
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  Card,
  Divider,
  Grid,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  TextField,
  Tooltip,
} from "@mui/material";
import HttpService from "../../../services/HttpService";
import React from "react";
import {
  Delete,
  Edit,
  Handshake,
  ToggleOff,
  ToggleOn,
} from "@mui/icons-material";
import debounce from "lodash.debounce";
import PlaceHolder from "../../templates/PlaceHolder";
import CustomModal from "../../templates/CustomModal";
import ConfirmModal from "../../templates/ConfirmModal";

const MembersList = () => {
  const [members, setMembers] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  React.useEffect(() => {
    getMembers();
  }, []);
  const getMembers = () => {
    setLoading(true);
    setLoaded(false);
    HttpService.post({}, "all_members")
      .then(
        (res) => {
          console.log(res);
          if (res.status === 1) {
            setMembers(res.data);
          }
        },
        (err) => {}
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      });
  };

  const togView = (index: number, state: boolean) => {
    console.log(index, state);
    const mutd = [...members];
    const item = (mutd[index]["is_togged"] = !mutd[index]["is_togged"]);
    setMembers(mutd);
  };

  let navigate = useNavigate();
  const togEdit = (i: any) => {
    return navigate(`/admin/members/e/${i.id}`);
  };

  const launchNew = (i: any) => {
    return navigate(`/admin/members/a/new`);
  };

  const delete_member = (item: any) => {
    setLoading(true);
    setLoaded(false);
    setDelModal({
      ...del_modal,
      loading: true,
      message: null,
      loading_message: "Working...",
    });
    HttpService.post({ id: item.id }, "remove_member")
      .then(
        (resp) => {
          if (resp.status === 1) {
            setModal({
              ...modal,
              onopen: true,
              severity: "success",
              message: resp.message,
            });
          }
          setTimeout(() => {
            setDelModal({
              ...del_modal,
              onopen: false,
              onclose: closeDelModal,
            });
            setModal({ ...modal, onopen: false, onclose: closeModal });
          }, 3000);
        },
        (error) => {
          setModal({
            ...modal,
            onopen: true,
            severity: "error",
            message: error.message,
          });
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //fetch
  };

  const closeModal = () => {
    setModal({ ...modal, onopen: false });
  };
  const [modal, setModal] = React.useState<any>({
    onopen: false,
    onclose: closeModal,
    title: "Edit member",
    loading_message: null,
    loading: false,
  });

  const activate_member = (item: any) => {
    setLoading(true);
    setLoaded(false);
    setDelModal({
      ...del_modal,
      loading: true,
      message: null,
      loading_message: "Working...",
    });
    HttpService.post(item, "activate_member")
      .then(
        (resp) => {
          if (resp.status === 1) {
            setModal({
              ...modal,
              onopen: true,
              severity: "success",
              message: resp.message,
            });
          } else {
            setModal({
              ...modal,
              onopen: true,
              severity: "error",
              message: resp.message,
            });
          }
          setTimeout(() => {
            setDelModal({
              ...del_modal,
              onopen: false,
              onclose: closeDelModal,
            });
            setModal({ ...modal, onopen: false, onclose: closeModal });
          }, 3000);
        },
        (error) => {
          setModal({
            ...modal,
            onopen: true,
            severity: "error",
            message: error.message,
          });
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //fetch
  };

  const closeDelModal = () => {
    setDelModal({ ...del_modal, onopen: false });
  };
  const [del_modal, setDelModal] = React.useState<any>({
    onopen: false,
    onclose: closeDelModal,
    title: "Delete member",
  });
  const launchDelete = (item: any) => {
    setDelModal({
      ...del_modal,
      id: item.id,
      loading_message: null,
      loading: false,
      message:
        "Are you sure you want to <strong><u>remove</u></strong> this member?",
      onopen: true,
      onclose: closeDelModal,
      onaccept: delete_member,
    });
  };
  const launchActivate = (item: any) => {
    const act = item.is_activated == "1" ? "deactivate" : "activate";
    setDelModal({
      ...del_modal,
      id: item.id,
      action: act,
      title: act + " member",
      message: `Are you sure you want to <strong><u>${act}</u></strong> this member?`,
      onopen: true,
      loading_message: null,
      loading: false,
      onclose: closeDelModal,
      onaccept: () => activate_member({ id: item.id, action: act }),
    });
  };

  const [searchTerm, setSearchTerm] = React.useState("");
  const handleSearchChange = (e: any) => {
    setSearchTerm(e.target.value);
  };

  const debouncedResults = React.useMemo(() => {
    return debounce(handleSearchChange, 300);
  }, []);

  React.useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  }, [searchTerm]);

  const runSearch = (keyword: string) => {
    setLoading(true);
    setLoaded(false);
    HttpService.post({ keyword: keyword }, "search_members")
      .then(
        (resp) => {
          console.log("Search results::", resp);
          if (resp.status === 1) {
          }
          setTimeout(() => {}, 3000);
        },
        (error) => {}
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //fetch
  };
  return (
    <div className="account-page-container">
      <Card>
        <div className="page-topper">
          <Breadcrumbs>
            <Link to="/admin">Dashboard</Link>
            <span>Members</span>
          </Breadcrumbs>
          <h1> All Members</h1>
        </div>
        <section className="page-detail-container">
          <div
            className="flex flex-row border-bottom
           align-items-center py10 px10"
          >
            <div className="px0">
              <span className="input my0">
                <input
                  type="search"
                  name="keyword"
                  className="input-form-control py5-i"
                  placeholder="Search Members"
                  onChange={handleSearchChange}
                />
              </span>
            </div>
            <div className="spacer"></div>
            <span>
              <Button variant="outlined" size="small" onClick={launchNew}>
                New
              </Button>
            </span>
          </div>
          <Grid container spacing={0}>
            {members.map((item: any, index: number) => (
              <Grid item xs={12} sm={6} md={4} lg={4} key={item.id}>
                <ListItem
                  disablePadding
                  sx={{ px: "10px", py: "3px" }}
                  key={index}
                  divider={index < members.length - 3 ? true : false}
                >
                  <ListItemAvatar>
                    <Avatar
                      variant="circular"
                      src={process.env.REACT_APP_SERVER_ROOT + item.thumb}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Box className="flex flex-row align-items-center">
                        <Box className="spacer" style={{ lineHeight: "1" }}>
                          <Link to={`/admin/members/p/${item.id}`}>
                            {item?.surname} {item?.firstname}
                          </Link>
                        </Box>
                        <span>
                          <Tooltip title="Edit member">
                            <IconButton
                              size="small"
                              onClick={() => togEdit(item)}
                            >
                              <Edit />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Remove member">
                            <IconButton
                              color="warning"
                              size="small"
                              onClick={() => launchDelete(item)}
                            >
                              <Delete />
                            </IconButton>
                          </Tooltip>
                          <Tooltip
                            title={
                              item.is_activated == "1"
                                ? "Deactivate member"
                                : "Activate member"
                            }
                          >
                            <IconButton
                              color={
                                item.is_active == "1" ? "default" : "primary"
                              }
                              size="small"
                              onClick={() => launchActivate(item)}
                            >
                              {item.is_active == "1" ? (
                                <ToggleOff />
                              ) : (
                                <ToggleOn />
                              )}
                            </IconButton>
                          </Tooltip>
                        </span>
                      </Box>
                    }
                  ></ListItemText>
                </ListItem>
                <Divider orientation="vertical" />
              </Grid>
            ))}
          </Grid>
          {loading && (
            <div className="px10">
              <PlaceHolder type="list" />
              <Divider />
              <PlaceHolder type="list" />
              <Divider />
              <PlaceHolder type="list" />
              <Divider />
              <PlaceHolder type="list" />
              <Divider />
              <PlaceHolder type="list" />
              <Divider />
              <PlaceHolder type="list" />
              <Divider />
              <PlaceHolder type="list" />
              <Divider />
              <PlaceHolder type="list" />
            </div>
          )}
        </section>
      </Card>
      {modal.onopen && <CustomModal data={modal} />}
      {del_modal.onopen && <ConfirmModal data={del_modal} loading={loading} />}
    </div>
  );
};

export default MembersList;
