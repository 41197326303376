import React from "react";
import { Grid } from "@mui/material";
import * as processHtml from "../../../services/processHtml";

const Ichie = () => {
  const [home, setHome] = React.useState<any>({});
  React.useEffect(() => {
    console.log(home, "::home");
  }, [home]);
  const { decodeHtml } = processHtml;
  return (
    <React.Fragment>
      {/*--Start counter-area  --*/}
      <div className="home-features medium-section">
        <div className="container">
          <div className="flex flex-row-resp align-items-center">
            <div className="thinking-desc spacer">
              {home?.igwe_section ? (
                <h2
                  dangerouslySetInnerHTML={{
                    __html: decodeHtml(home?.igwe_section?.header),
                  }}
                ></h2>
              ) : (
                <h2>Message from Ichie Diokwu</h2>
              )}
              {/**<p>
                Building with our residents is something we keep close to our
                heart. Getting to know their personal stories and celebrating
                them together is what makes the care we provide truly
                personalized.
  </p>**/}
              <div className="fearture-pads">
                <Grid container spacing={2.5}>
                  <Grid item xs={12} sm={12} md={12}>
                    <div className="pad-container my30">
                      <div className="info-text-area">
                        {home?.igwe_section ? (
                          <p
                            dangerouslySetInnerHTML={{
                              __html: decodeHtml(home?.igwe_section?.message),
                            }}
                          ></p>
                        ) : (
                          <p>
                            Building with our residents is something we keep
                            close to our heart. Getting to know their personal
                            stories and celebrating them together is what makes
                            the care we provide truly personalized.
                          </p>
                        )}
                      </div>
                      <div className="pad-head">
                        <span className="pad-icon">
                          <i className="fas fa-pen"></i>
                        </span>
                        <div className="pad-text spacer">
                          <div className="sub">Signed</div>
                          {home?.igwe_section ? (
                            <h3
                              dangerouslySetInnerHTML={{
                                __html: decodeHtml(home?.igwe_section?.name),
                              }}
                            ></h3>
                          ) : (
                            <h3>
                              Chief (Dr.) Benedict Chinwendu Udegbe <br />
                              (Ichie Diokwu III)
                            </h3>
                          )}
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
            {/*--thinking-desc ends  --*/}
            <div className="thinking-banner spacer">
              <img src="/images/ichie.png" alt="" />
            </div>
            {/*--thinking-banner ends  --*/}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Ichie;
