import React from "react";
import HttpService from "../services/HttpService";

const useFetchHomepageDetail = (props: any) => {
  console.log("prps:", props);
  const { slug } = props;
  const [homepage_detail, setHome] = React.useState<any>(null);
  const [loading_homepage, setLoading] = React.useState(false);
  const [loaded_homepage, setLoaded] = React.useState(false);

  React.useEffect(() => {
    if (slug) {
      fetch_case(slug);
    }
  }, [slug]);

  const fetch_case = (url: string) => {
    setLoading(true);
    setLoaded(false);
    HttpService.postHeader("get_homepage_detail", { slug: url })
      .then(
        (res) => {
          if (res.status === 1) {
            setHome(res.data);
          }
        },
        (err) => {
          console.log(err);
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      });
  };

  return {
    homepage_detail,
    loading_homepage,
    loaded_homepage,
  };
};

export default useFetchHomepageDetail;
