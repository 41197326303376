import { Breadcrumbs, Card } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import EditPage from "./EditPage";

const Committees = () => {
  return (
    <React.Fragment>
      <div className="account-page-container">
        <Card>
          <div className="page-topper">
            <Breadcrumbs>
              <Link to="/admin">Dashboard</Link>
              <Link to="/admin/about">About</Link>
              <span>Committees</span>
            </Breadcrumbs>
            <h1>Committees</h1>
          </div>
          <section className="page-detail-container">
            <EditPage slug="committees" />
          </section>
        </Card>
      </div>
    </React.Fragment>
  );
};

export default Committees;
