import React from "react";
import Header from "../../Header/Header";
import PageIntro from "../PageIntro/PageIntro";
import useFetchEventCategories from "../../../hooks/useFetchEventCategories";
import { Link } from "react-router-dom";
import Footer from "../../Footer/Footer";
import SeoModule from "../../../services/SeoModule";
const EventsLayout = (props: any) => {
  const { children, page_data } = props;
  const {
    event_categories,
    loading_event_categories,
    loaded_event_categories,
  } = useFetchEventCategories();

  let nave = [{ title: "All", slug: "" }, ...event_categories];

  const [default_tab, setDefTab] = React.useState({
    title: "All",
    icon: "fa-list",
  });
  const [cur_tab, setTab] = React.useState<any>("");
  React.useEffect(() => {
    setTab(page_data?.page_slug);
  }, [page_data]);

  const [isTogged, setIsTogged] = React.useState(false);
  const toggleNav = () => {
    const cur = isTogged;
    setIsTogged(!cur);
  };

  const doTab = (item: any) => {
    setTab(item?.slug);
    setDefTab(item);
    toggleNav();
  };

  return (
    <React.Fragment>
      <SeoModule
        title={`Dianaokwu Ukaoye - Events - ${page_data?.page_title}`}
        description={page_data?.page_summary}
        name="Reva"
        page_url={process.env.REACT_APP_SERVER_DOMAIN}
        page_image={process.env.REACT_APP_SERVER_DOMAIN + "images/logo.png"}
        type="Crypto & Investment"
      />
      <section className="page-main">
        <div className="home-overlay"></div>
        <Header />
        <PageIntro
          title={page_data.page_title}
          summary={page_data.page_summary}
          loading_page={false}
        />
      </section>

      <section className="container py20">
        {event_categories && (
          <div className="mb20">
            <div className={`nav-cover`}>
              <span className="def-sub" onClick={toggleNav}>
                <span className="spacer">
                  <i className={`fas ${default_tab.icon} pr5 txt-sm`}></i>
                  {default_tab.title}
                </span>
                <span className="mobile-nav">
                  <button onClick={toggleNav} className="mnav dark">
                    <i
                      className={`fas fa-${
                        !isTogged ? "chevron-down" : "chevron-up"
                      }`}
                    ></i>
                  </button>
                </span>
              </span>
              <ul className={`category-tab  ${isTogged ? "flex" : "none"}`}>
                {nave.map((item, index) => (
                  <li key={item.title}>
                    <Link
                      to={`/events/${item.slug}`}
                      onClick={() => doTab(item)}
                      className={
                        item.slug === page_data.page_slug ? "active" : ""
                      }
                    >
                      {item.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}

        <div className="p0">{children}</div>
      </section>
    </React.Fragment>
  );
};

export default EventsLayout;
